<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col class="h-full" cols="12" md="6">
          <p class="text-black pt-0 px-0 fs-20 font-weight-bold">
            Stamping Overview
          </p>
          <v-row>
            <v-col v-for="item in dataGrid" :key="item.name" cols="12" md="4">
              <v-skeleton-loader
                v-if="loading"
                height="127"
                loading
                type="image"
              />
              <v-card v-else class="h-full" outlined>
                <v-card-text class="py-8">
                  <div class="text-center text-h3">
                    {{ data.overview ? data.overview[item.key] : "" }}
                  </div>
                  <div class="text-center">{{ item.name }}</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6">
          <p class="text-black pt-0 px-0 fs-20 font-weight-bold">
            Stok
          </p>
          <v-skeleton-loader v-if="loading" height="127" loading type="image" />
          <v-card v-else outlined class="py-8">
            <v-row>
              <v-col cols="12" md="6">
                <div class="text-center mb-2 text-h3">
                  {{ data.stock ? data.stock.balance : "" }}
                </div>
                <div class="text-center mb-2">Stok yang tersedia</div>
                <div class="text-center">
                  <v-btn
                    x-small
                    color="primary"
                    @click="$router.push('/inventory/products')"
                    >Tambah Stok</v-btn
                  >
                </div>
              </v-col>

              <v-col cols="12" md="6">
                <div class="text-center mb-2 text-h3">
                  {{ data.stock ? data.stock.used : "" }}
                </div>
                <div class="text-center mb-2">Stok yang terpakai</div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-row class="mb-4" justify-md="space-between" no-gutters align="center">
        <p class="text-black pt-0 px-0 fs-20 font-weight-bold">
          Dokumen
        </p>

        <v-text-field
          v-model="search"
          outlined
          append-icon="$search"
          placeholder="Search"
          solo
          flat
          style="width: 200px; max-width: 100%; flex: none"
        ></v-text-field>
      </v-row>

      <v-skeleton-loader v-if="loading" height="400" loading type="image" />
      <v-data-table
        v-else
        :headers="headers"
        :items="data.document ? data.document.data : []"
        :page.sync="page"
        :items-per-page.sync="limit"
        :server-items-length="
          data.document ? data.document.pagination.total_data : 0
        "
      >
        <template #item.status="{item}">
          <v-chip
            small
            v-if="item.status == 'process'"
            color="warning"
            outlined
            >{{ item.status }}</v-chip
          >
          <v-chip
            small
            v-else-if="item.status == 'failed'"
            color="danger"
            outlined
            >{{ item.status }}</v-chip
          >
          <v-chip
            small
            v-else-if="item.status == 'success'"
            color="success"
            outlined
            >{{ item.status }}</v-chip
          >
          <v-chip small v-else color="primary" outlined>{{
            item.status
          }}</v-chip>
        </template>
        <template #item.action="{item}">
          <div class="d-flex flex-nowrap">
            <v-btn
              class="mr-2"
              :disabled="item.status == 'completed'"
              x-small
              @click="handleClickRestamp(item.documentId)"
              >Restamp</v-btn
            >
            <v-btn
              :disabled="item.status != 'completed'"
              x-small
              @click="handleClickResend(item.documentId)"
              >Kirim Ulang Email Completed</v-btn
            >
          </div>
        </template>
      </v-data-table>
    </v-col>

    <v-snackbar v-model="showAlert" top text :color="alertType">
      {{ alertMessage }}
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  data: () => {
    return {
      loading: false,
      data: {},
      page: 1,
      limit: 10,
      search: "",
      searchTimeout: undefined,
      showAlert: false,
      alertMessage: "",
      alertType: "",
    };
  },
  computed: {
    dataGrid() {
      return [
        { name: "Total berjalan (draft)", key: "draft" },
        { name: "Total diproses", key: "totalUpload" },
        { name: "Total berhasil", key: "success" },
      ];
    },
    headers() {
      return [
        { text: "ID DOKUMEN", value: "documentId" },
        { text: "EMAIL", value: "email" },
        { text: "TANGGAL UPLOAD", value: "date_uploaded" },
        { text: "STATUS", value: "status" },
        { text: "JUMLAH DIGUNAKAN", value: "used" },
        { text: "EXPECTED", value: "expected" },
        { text: "SUKSES", value: "success" },
        { text: "GAGAL", value: "failed" },
        { text: "", value: "action" },
      ];
    },
  },
  watch: {
    page() {
      this.fetchOverview();
    },
    limit() {
      this.fetchOverview();
    },
    search() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = undefined;
      this.searchTimeout = setTimeout(() => {
        if (this.page != 1) this.page = 1;
        else this.fetchOverview();
      }, 3e2);
    },
  },
  mounted() {
    this.fetchOverview();
  },
  methods: {
    async fetchOverview() {
      this.loading = true;
      const { data: res } = await this.axios
        .get("econtract/agent/overview", {
          params: {
            page: this.page,
            limit: this.limit,
            search: this.search,
          },
        })
        .finally(() => {
          this.loading = false;
        });
      this.data = res.data;
    },
    async handleClickRestamp(document_id) {
      this.loading = true;
      const { data: res } = await this.axios
        .post("econtract/agent/restamping_document", {
          document_id,
        })
        .then(async () => {
          this.alertType = "success";
          this.showAlert = true;
          this.alertMessage = "Berhasil melakukan proses pembubuhan ulang.";
          await this.fetchOverview();
        })
        .catch(() => {
          this.alertType = "error";
          this.showAlert = true;
          this.alertMessage =
            "Gagal melakukan pembubuhan ulang, silakan coba beberapa saat lagi";
        })
        .finally(() => {
          this.loading = false;
        });
      console.log(res);
    },
    async handleClickResend(document_id) {
      this.loading = true;
      const { data: res } = await this.axios
        .post("econtract/microsite-service/resend/document", {
          document_id,
        })
        .then(async () => {
          this.alertType = "success";
          this.showAlert = true;
          this.alertMessage = "Berhasil melakukan kirim ulang email.";
          await this.fetchOverview();
        })
        .catch(() => {
          this.alertType = "error";
          this.showAlert = true;
          this.alertMessage =
            "Gagal melakukan kirim ulang email, silakan coba beberapa saat lagi";
        })
        .finally(() => {
          this.loading = false;
        });
      console.log(res);
    },
  },
};
</script>

<style></style>
