<template>
  <v-row align-content="center" class="">
    <v-col class="py-0 mx-auto" md="10" style="min-height: 100vh;">
      <v-row>
        <v-col v-show="send" class="mx-auto" md="6">
          <v-spacer class="py-10"></v-spacer>
          <div class="mx-auto text-center">            
            <img src="@/assets/images/img-logo-black.png" width="200" class="mb-5">          
          </div>
          <v-form
            ref="form"
            class="px-8 white rounded"
            @submit.prevent="submit"
            v-model="valid"            
          > 
            <div class="mx-auto text-center mb-3 text-black py-3">
              <p class="font-weight-bold title-login">Konfirmasi Anggota</p>
            </div>
            <v-row>
              <v-col cols="12" class="py-0 text-center">
                <p class="text-muted">Masukkan formulir di bawah ini untuk mengonfirmasi keanggotaan Anda</p>
              </v-col>
            </v-row>
            <v-alert
              class="mt-2"
                type="error"
                text
                dense
                prominent
                v-show="response !== null"
                >{{ response }}</v-alert
              >          
            <v-row>
              <v-col class="">
                <div class="subtitle-2 mb-2 text-black mt-1">Nama Lengkap</div>
                <v-text-field
                  filled
                  v-model="fullname"
                  :rules="[v => !!v || 'Full Name is required']"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
                <!-- :rules="[...rules('Full Name', 'required'), validate]" -->
              </v-col>
            </v-row>

            <v-row>
              <v-col md="">
                <div class="subtitle-2 mb-2 text-black mt-3">Nomor Telepon</div>
                <v-text-field
                  prepend-inner-icon="ri-phone-fill"
                  type="number"
                  filled
                  v-model="phone"
                  :rules="[v => !!v || 'Nomor Telepon diperlukan']"
                  required
                  outlined
                  hide-details="auto"
                  class="prepend"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="">
                <div class="subtitle-2 mb-2 text-black mt-1">Kata Sandi</div>
                <v-text-field
                  filled
                  :append-icon="show1 ? 'ri-eye-line' : 'ri-eye-off-fill'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  v-model="password"
                  :rules="[...rules('Kata sandi diperlukan', 'required'), validate]"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="">
                <div class="subtitle-2 mb-2 text-black mt-1">Konfirmasi Kata Sandi</div>
                <v-text-field
                  filled
                  :append-icon="show2 ? 'ri-eye-line' : 'ri-eye-off-fill'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                  v-model="re_password"
                  :rules="[...rules('Konfirmasi kata sandi diperlukan', 'required'), validate]"
                  required
                  outlined
                  hide-details="auto"
                  @change="validatePassword(re_password)"
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" class="">
                <v-btn
                  large
                  elevation="0"
                  type="submit"
                  color="primary"
                  :loading="submiting"
                  block
                >
                  Daftar
                </v-btn>
                
              </v-col>              
            </v-row>
          </v-form>
          
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import validationMixin from "../_mixins/validation";

export default {
  mixins: [validationMixin],
  name: "MemberConfirmation",
  data() {
    return {
      show1: false,
      show2: false,
      valid: true,
      errors: null,
      send:true,
      fullname: "",
      phone: "",
      password: "",
      re_password: "",
      submiting: false,
      show:false,
      response:null
    };
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
  },
  methods: {
    validatePassword(repassword){
      if(this.password !== repassword){
        this.errors = {
          message: "Password and Confirm Password must be same"
        };
      }else{
        this.errors = null;
      }
    },
    submit() {    
      this.validatePassword();  
      // setTimeout(() => {
      // if (this.$refs.form.validate()) {
      //   this.submiting = true;
      //   let data = { email:this.email};

      //   this.axios
      //     .post(
      //       `auth/users/forgot_password`,data,
      //       {
      //         headers: {"Content-Type": "application/json"},
      //       })
      //     .then(() => {
      //       this.submiting = false;            
      //       this.cekEmail=true;
      //       this.send=false;
      //     })
      //     .catch((error) => {
      //       this.submiting = false;
      //       this.$refs.form.reset();
      //       // console.log('er',error.response)            
      //       this.response = error.response.data.message;
      //       setTimeout( () => {                
      //         this.response=null;
      //       }
      //       , 3000);
      //     });
      //   } 
      // }, 50);
    },
  },
};
</script>

<style scoped>
.prepend .v-icon.v-icon {
  font-size: 14px !important;
}
</style>