<!-- @format -->

<template>
  <div>
    <v-card v-if="!isLoading && !hasError" rounded="lg" class="transparent">
      <v-breadcrumbs :items="items" class="px-0">
        <template v-slot:divider>
          <v-icon>ri-arrow-right-s-line</v-icon>
        </template>
      </v-breadcrumbs>
      <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold"
        >Certificate</v-card-title
      >

      <v-card-text class="px-0">
        <div class="content">
          <div class="table my-3">
            <v-card class="mt-5">
              <v-card-title class="font-weight-regular">
                <v-row align="center">
                  <v-col lg="6">
                    <p class="fs-16 text-black font-weight-medium">
                      {{ data.title }}
                    </p>
                  </v-col>

                  <v-col
                    id="search"
                    lg="6"
                    class="justify-end align-end text-right"
                  >
                    <v-btn
                      class="text-right"
                      color="primary"
                      elevation="0"
                      :to="{
                        name: 'create-certificate-receive',
                        params: {id: data.id || 0},
                      }"
                    >
                      Create Certificate
                    </v-btn>
                  </v-col>
                </v-row>
                <div class="col-md-12 px-0 py-4">
                  <v-divider></v-divider>
                </div>
                <div v-if="!isLoading" class="row">
                  <div class="col-md-4 px-1">
                    <v-card rounded="lg" class="transparent px-0">
                      <v-card-text class="py-0">
                        <v-list three-line>
                          <v-list-item class="pl-0">
                            <img
                              :src="
                                data.preview ||
                                  `https://via.placeholder.com/728x400.png?text=${data.title}`
                              "
                              class="img-table mr-1 ms-0"
                            />
                            <v-list-item-content>
                              <p class="text-semu fs-12 mb-2 wb">
                                CERTIFICATE TITLE
                              </p>
                              <p class="fs-14 text-black font-weight-medium wb">
                                {{ data.title }}
                              </p>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </div>
                  <v-divider vertical></v-divider>
                  <div class="col-md-2 px-1">
                    <v-card rounded="lg" class="transparent px-0">
                      <v-card-text class="py-5">
                        <p class="text-semu fs-12 mb-2 wb">
                          ISSUANCE DATE
                        </p>
                        <p class="fs-14 text-black font-weight-medium wb">
                          {{ data.created_at | moment("dddd, D MMMM YYYY") }}
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <v-divider vertical></v-divider>
                  <div class="col-md-2 px-1">
                    <v-card rounded="lg" class="transparent px-0">
                      <v-card-text class="py-5">
                        <p class="text-semu fs-12 mb-2 wb">RECIPIENTS</p>
                        <p class="fs-14 text-black font-weight-medium wb">
                          {{ data.recipient }} Recipients
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <v-divider vertical></v-divider>
                  <div class="col-md-2 px-1">
                    <v-card rounded="lg" class="transparent px-0">
                      <v-card-text class="py-5">
                        <p class="text-semu fs-12 mb-2 wb">LAST UPDATED</p>
                        <p class="fs-14 text-black font-weight-medium wb">
                          {{ data.updated_at | moment("dddd, D MMMM YYYY") }}
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <v-divider vertical></v-divider>
                  <div class="col-md-2 px-1">
                    <v-card rounded="lg" class="transparent px-0">
                      <v-card-text class="py-5">
                        <v-btn
                          @click="preview = true"
                          block
                          outlined
                          color="primary"
                          class="mb-2"
                          >Preview</v-btn
                        >
                        <v-dialog v-model="preview" max-width="60%">
                          <v-card class="d-flex justify-center items-center">
                            <img
                              style="width: 100%; height: 100%; object-fit: cover;"
                              :src="data && data.preview"
                              alt="image"
                            />
                          </v-card>
                        </v-dialog>
                        <v-btn
                          :to="{
                            name: 'edit-template',
                            params: {
                              id: data.id,
                            },
                          }"
                          block
                          outlined
                          color="primary"
                          class=""
                        >
                          Edit
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
                <div class="col-md-12 px-0 py-4">
                  <v-divider></v-divider>
                </div>
                <!-- <div class="row">
                  <div class="col-md-6">
                    <v-text-field
                      outlined
                      append-icon="$search"
                      class="mt-1 ms-2 mx-width-85"
                      placeholder="Search"
                      solo
                      flat
                    ></v-text-field>
                  </div>
                  <div class="col-md-6 text-end align-end">
                    <v-text-field
                      outlined
                      append-icon="ri-filter-fill"
                      class="mt-1 ms-2 mx-width-85 float-right"
                      placeholder="Filter"
                      solo
                      flat
                    ></v-text-field>
                  </div>
                </div> -->
              </v-card-title>
              <div v-if="!recipient" class="col-md-6 mx-auto my-2">
                <img src="@/assets/images/certif-empty.svg" />
                <p class="font-weight-bold text-black mb-2">
                  Empty Certificate
                </p>
                <p class="text-semu">
                  Create a new certificate or try to narrow the search if you
                  can't find the certificate you're looking for
                </p>
              </div>
              <!-- <v-data-table
                v-else
                :headers="headers"
                :items="recipient"
                :search="search"
              >
                <template v-slot:[`item.status`]="{item}">
                  <v-chip
                    v-if="item.status === 'Issued'"
                    color="success"
                    outlined
                    small
                  >
                    {{ item.status }}
                  </v-chip>
                  <v-chip
                    v-else
                    :color="item.status == 'Prececced' ? '#F0AB0B' : '#2665DE'"
                    outlined
                    small
                  >
                    {{ item.status }}
                  </v-chip>
                </template>
              </v-data-table> -->
            </v-card>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="hasError">
      {{ message }}
      <template v-slot:action="{attrs}">
        <v-btn color="red" text v-bind="attrs" @click="hasError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {onMounted, reactive, toRefs} from "@vue/composition-api";
import useFetch from "../../../hooks/useFetch";
import api from "../../../store/api";
export default {
    created() {
    document.title = "Template Detail-Pensign Admin";
  },
  setup(props, ctx) {
    const {fetchData} = useFetch();
    const route = ctx.root._route;

    const state = reactive({
      items: [
        {
          text: "E-Certificate",
          disabled: false,
          href: "#",
        },
        {
          text: "Certificate",
          disabled: false,
          href: "/template",
        },
        {
          text: "Detail",
          disabled: true,
          href: "#",
        },
      ],
      search: "",
      headers: [
        {
          text: "PENERIMA",
          align: "start",
          sortable: false,
          value: "penerima",
        },
        {text: "EMAIL", value: "email"},
        {text: "JUDUL SERTIFIKAT", value: "judul"},
        {text: "TANGGAL DITERBITKAN", value: "date"},
        {text: "TANGGAL KADALUARSA", value: "exp"},
        {text: "STATUS", value: "status"},
      ],
      data: null,
      recipient: [],
      isLoading: true,
      message: "",
      hasError: false,
      preview: false,
    });

    const getDataById = async () => {
      state.isLoading = true;
      let data = await fetchData({
        url: api.template_detail + route.params.id,
        method: "get",
      });
      if (!data.hasError) {
        state.data = data.response.data.template;
        state.items[2].text = data.response.data.template.title;
      } else {
        state.hasError = true;
        state.message = data.message;
      }
      state.isLoading = false;
    };
    // const getCridentials = async () => {
    //   state.isLoading = true;
    //   let data = await fetchData({
    //     url: api.cridetial_detail + route.params.id,
    //     method: 'get'
    //   });
    //   if (!data.hasError) {
    //     state.recipient = data.response;
    //     console.log('heii',data);
    //   } else {
    //     state.hasError = true;
    //     state.message = data.message;
    //   }
    //   console.log('heii',data);
    //   state.isLoading = false;
    // };
    onMounted(() => {
      getDataById();
      // getCridentials();
    });
    return {
      props,
      ...toRefs(state),
    };
  },
};
</script>
