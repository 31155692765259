<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>

    <v-alert
      v-if="isTransferred"
      text
      type="orange"
      icon="ri-error-warning-line"
    >
      <p class="text-black mb-2">You have deposit bills to add funds to your PENSIGN fund. Make a bank transfer to before <span class="font-weight-bold">October 13th, 2021 9:30am.</span></p>
      <div class="d-flex align-center">
        <p class="font-weight-bold text-black mr-2 mb-0">Have you transferred? Click the button below to confirm.</p>
        <v-btn color="primary" class="white--text">
          Confirm Transfer
        </v-btn>
      </div>
    </v-alert>

    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">
      <div class="row">
        <div class="col-md-6">
          <p class="text-black pt-0 px-0 fs-20 font-weight-bold mb-0">Deposit</p>
        </div>
        <div class="col-md-6 text-right">
          <v-btn color="primary" class="white--text mr-2" :to="{ name: 'balance-deposit'}">
            <v-icon class="mr-2">ri-add-circle-fill</v-icon> Add Fund
          </v-btn>
          <v-btn color="primary" outlined class="white--text">
            Withdraw
          </v-btn>
        </div>
      </div>
    </v-card-title>

    <v-card-text class="px-0">
      <div class="">
        <div class="row mb-5">
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu">AVAILABLE FUND</p>
                <p class="fs-18 text-black">Rp {{ formatPrice(deposit_balance) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu">TOTAL OUTGOING</p>
                <p class="fs-18 text-black">Rp {{ formatPrice(deposit_outgoing) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu">TOTAL INCOMING</p>
                <p class="fs-18 text-black">Rp {{ formatPrice(deposit_incoming) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu">TOTAL PENDING</p>
                <p class="fs-18 text-black">{{ deposit_total_transaction }}</p>
              </v-card-text>
            </v-card>
          </div>
        </div>

        <div class="table my-3">
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">

              <div class="row">
                <div class="col-md-2 px-0">
                  <v-select
                    v-model="columnFilter.status"
                    :items="ex"
                    :item-value="`value[0]`"
                    item-text="label"
                    placeholder="Select Status"
                    @change="getListDeposit(columnFilter.ex)"
                    class="ma-1"
                  ></v-select>
                </div>
                <div class="col-md-3 px-0 d-none">
                  <v-select
                    v-model="columnFilter.type"
                    :items="transaction_type"
                    :item-value="`value`"
                    item-text="label"
                    placeholder="Select Transaction Type"
                    @change="getListDeposit(columnFilter.type)"
                    class="ma-1"
                  ></v-select>
                </div>
                <div class="col-md-3 px-0">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    elevation="0"
                  >
                    <!-- :max="(new Date()).toISOString()" -->
                    <v-date-picker
                      ref="picker"
                      v-model="columnFilter.date"
                      min="1950-01-01"
                      outlined
                      required
                      range
                      @change="getListDeposit(columnFilter.date)"
                    ></v-date-picker>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRangeText"
                        placeholder="Start date - End date"
                        append-icon="ri-calendar-line"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="mt-1"
                      ></v-text-field>
                    </template>
                  </v-menu>
                </div>
                <div class="col-md-4 px-0">
                  <v-text-field
                      outlined
                      v-model="search"
                      append-icon="$search"
                      class="mt-1 ms-2"
                      placeholder="Search"
                      solo
                      flat
                    ></v-text-field>
                </div>
                <div class="col-md-2 px-0">
                  <v-btn color="grey darken-4" class="white--text ma-1" @click="dialog=true" style="min-height: 48px;">
                    <v-icon class="mr-2">ri-download-cloud-fill </v-icon> Export
                  </v-btn>
                </div>
              </div>
            </v-card-title>

            <div v-if="!listTransaction" class="col-md-6 mx-auto my-2">
              <img src="@/assets/images/empty.png">
              <p class="font-weight-bold text-black mb-2">No Disbursement transaction found.</p>
              <p class="text-semu">All transaction of your Pensign account will appear here.</p>
              <v-btn color="primary" class="ma-1" outlined>
                Create Disbursement
              </v-btn>
            </div>

            <v-data-table
              v-else
              :headers="headers"
              :items="listTransaction"
              :search="search"
              :loading="isLoading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ formatDate(item.createdAt) }}
              </template>

              <template v-slot:[`item.updatedAt`]="{ item }">
                {{ parseTime(item.createdAt) }}
              </template>

              <template v-slot:[`item.amount`]="{ item }">
                {{ formatPrice(item.amount) }}
              </template>

              <template v-slot:[`item.total_amount`]="{ item }">
                {{ formatPrice(item.total_amount) }}
              </template>

              <template v-slot:[`item.transaction_type`]="{ item }">
                <v-chip
                  color="#00796B"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.transaction_type }}
                </v-chip>
              </template>
            </v-data-table>

          </v-card>

          <v-snackbar
            v-model="isError"
          >
            {{ showMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="isError = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </div>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Export Transaction History Report
        </v-card-title>

        <v-card-text class="my-3">
          <p class="mb-0">Select File Type</p>
          <v-select
            :items="ex"
            placeholder="Select Type"
            class="ma-1"
          ></v-select>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="dialog = false"
            class="text-center"
          >
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import store from "../../store";
import { parseTime, formatDate, formatPrice } from "@/lib/formatDate";
import { mapGetters, mapActions } from 'vuex';

export default {
  data: () => ({
    dialog: false,
    isTransferred: false,
    menu: false,
    isLoading:false,
    items: [
      {
        text: 'Fund',
        disabled: false,
        href: '#',
      }
    ],
    search: '',
    headers: [
      { text: 'DATE', value: 'createdAt' },
      { text: 'TIME', value: 'updatedAt' },
      { text: 'TRANSACTION TYPE', value: 'transaction_type' },
      { text: 'AMOUNT (RP)', value: 'amount', align: 'right' },
      { text: 'FUND (RP)', value: 'total_amount', align: 'right' },
    ],
    ex: [],
    transaction_type: [
      {
        label: "Deposit",
        value: "deposit"
      }
    ],
    columnFilter: {
      status: [],
      type: [],
      date: [],
    },
    isError: false,
    showMessage: '',
    listTransaction: []
  }),
  mounted() {
    this.getListDeposit();
    this.getSummaryDeposit();
  },
  computed: {
    dateRangeText () {
      return this.columnFilter.date.join(' ~ ')
    },
    ...mapGetters({
      deposit_balance: 'getDepositBalance',
      deposit_incoming: 'getDepositIncoming',
      deposit_outgoing: 'getDepositOutgoing',
      deposit_total_transaction: 'getDepositTotalTransaction',
    }),
  },
  methods: {
    ...mapActions({ getSummaryDeposit: 'getSummaryDeposit' }),

    getListDeposit() {
      this.isLoading = true;
      this.$store.dispatch("getListDeposit", this.columnFilter)
      .then(response => {
        if (response.data.code == 401) {
          this.isError = true;
          this.showMessage = response.data.message;
          setTimeout( () => {
            localStorage.removeItem("pensign_auth");
            delete axios.defaults.headers.common["x-access-token"];
            store.commit("RESET_USER");
            this.$router.push({name: "login"})
          }, 1000);
        } else {
          const { data } = response;
          this.ex = data.result.filter.status;
          this.listTransaction = data.result.transaction;
          this.isLoading = false;
        }
      })
      .catch(error => {
        console.log(error);
        this.isLoading = false;
        // this.isError = true;
        // this.showMessage = error.response.data.message;
      });
    },

    formatDate(value) {
      return formatDate(value);
    },
    formatPrice(value) {
      return formatPrice(value);
    },
    parseTime(value) {
      return parseTime(value);
    },
  },
};
</script>

<style>
</style>