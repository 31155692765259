<template>
  <v-row>
    <v-col class="pa-0 white overflow-auto" cols="12">
      <v-container class="py-0">
        <v-row
          justify="space-between"
          align="center"
          no-gutters
          style="height: 64px"
        >
          <v-col cols="6">
            <v-img :src="LOGO" height="24" contain position="left"></v-img>
          </v-col>
          <v-col cols="6" class="text-right">
            <a href="https://wa.me/6285795641917" target="_blank">
              <v-btn rounded text>
                <span class="d-none d-sm-inline">
                  Membutuhkan bantuan?
                </span>
                <v-icon right>ri-message-line</v-icon>
              </v-btn>
            </a>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
    </v-col>

    <v-col
      class="px-sm-16 white"
      cols="12"
      md="7"
      style="min-height: calc(100vh - 65px)"
    >
      <v-tabs v-model="tabActive" class="custom-tabs mt-8 mb-10" height="64">
        <v-tab disabled>
          <div
            class="text-left"
            :class="tabActive != 0 ? 'd-none d-sm-block' : ''"
          >
            <div
              class="text-none mb-1 font-weight-regular "
              style="font-size: 13px"
            >
              Langkah 1
            </div>
            <div class="font-weight-bold text-none">Buat Profil Anda</div>
          </div>
        </v-tab>
        <v-tab disabled>
          <div
            class="text-left"
            :class="tabActive != 1 ? 'd-none d-sm-block' : ''"
          >
            <div
              class="text-none mb-1 font-weight-regular"
              style="font-size: 13px"
            >
              Langkah 2
            </div>
            <div class="font-weight-bold text-none">Siapkan Tim</div>
          </div>
        </v-tab>
        <v-tab disabled>
          <div
            class="text-left"
            :class="tabActive != 2 ? 'd-none d-sm-block' : ''"
          >
            <div
              class="text-none mb-1 font-weight-regular"
              style="font-size: 13px"
            >
              Langkah 3
            </div>
            <div class="font-weight-bold text-none">
              Tambah Anggota Tim
            </div>
          </div>
        </v-tab>
      </v-tabs>

      <div class="d-block d-md-none mb-8">
        <div class=" text-h5 mb-2">
          {{ section.title }}
        </div>
        <div class="">
          {{ section.subtitle }}
        </div>
      </div>

      <template>
        <v-row justify="center">
          <v-col cols="12" :lg="tabActive == 2 ? 12 : 8">
            <!-- ini buat step satu -->
            <v-form
              v-if="tabActive == 0"
              ref="formData"
              lazy-validation
              :disabled="loading.submit"
            >
              <div class="mb-4">
                <div class="mb-2 font-weight-bold">Nama Lengkap</div>
                <v-text-field
                  v-model="bioData.full_name"
                  :rules="[requiredRule('Nama lengkap')]"
                  type="text"
                >
                  <template #append>
                    <div class="grey--text text--lighten-1"></div>
                  </template>
                </v-text-field>
              </div>

              <div class="mb-4">
                <div class="mb-2 font-weight-bold">Nomor Telepon</div>
                <v-text-field
                  v-model="bioData.phone"
                  :rules="[
                    requiredRule('Nomor telepon'),
                    numberRule('Nomor telepon'),
                  ]"
                  type="text"
                >
                </v-text-field>
              </div>

              <div class="mb-4">
                <div class="mb-2 font-weight-bold">
                  Nama Perusahaan
                  <v-tooltip bottom>
                    <template #activator="{on, attrs}">
                      <v-icon size="16" v-bind="attrs" v-on="on"
                        >ri-information-line</v-icon
                      >
                    </template>
                    <div class="text-center" style="width: 200px">
                      Harap masukkan nama perusahaan sesuai akta pendirian
                      perusahaan.
                    </div>
                  </v-tooltip>
                </div>
                <v-text-field
                  v-model="bioData.company_name"
                  :rules="[requiredRule('Nama perusahaan')]"
                  type="text"
                >
                </v-text-field>
              </div>

              <div class="mb-4">
                <div class="mb-2 font-weight-bold">
                  Provinsi
                  <v-icon size="16">ri-information-line</v-icon>
                </div>
                <v-select
                  v-model="bioData.province_id"
                  required
                  outlined
                  hide-details="auto"
                  :items="provinces"
                  item-text="name"
                  item-value="id"
                  :rules="[requiredRule('Provinsi')]"
                  @change="getCities($event)"
                >
                </v-select>
              </div>

              <div class="mb-4">
                <div class="mb-2 font-weight-bold">
                  Kota / Kabupaten
                  <v-icon size="16">ri-information-line</v-icon>
                </div>
                <v-select
                  v-model="bioData.city_id"
                  required
                  outlined
                  hide-details="auto"
                  :items="cities"
                  item-text="name"
                  item-value="id"
                  :rules="[requiredRule('Kota / Kabupaten')]"
                >
                </v-select>
              </div>
            </v-form>

            <!-- ini buat step dua -->
            <v-form
              v-else-if="tabActive == 1"
              ref="formData"
              lazy-validation
              :disabled="loading.submit"
            >
              <div class="mb-4">
                <div class="mb-2 font-weight-bold">Nama Tim</div>
                <v-text-field
                  v-model="domain"
                  :loading="loading.domain"
                  :readonly="isTeamAvail"
                  :background-color="isTeamAvail ? 'grey lighten-3' : ''"
                  :rules="[requiredRule('Nama tim'), domainRule('Nama domain')]"
                  type="text"
                >
                  <template #append>
                    <div class="grey--text text--lighten-1">
                      .{{ subdomain }}
                    </div>
                  </template>
                </v-text-field>

                <v-row
                  v-if="isTeamAvail != undefined"
                  no-gutters
                  class="mt-2"
                  align="center"
                >
                  <v-icon
                    class="mr-1"
                    :color="isTeamAvail ? 'success' : 'error'"
                    size="16"
                    >ri-checkbox-circle-line</v-icon
                  >
                  <span :class="isTeamAvail ? 'success--text' : 'error--text'">
                    Nama domain
                    {{ isTeamAvail == false ? "tidak" : "" }} tersedia
                  </span>
                </v-row>
              </div>

              <div class="mb-4">
                <v-btn
                  :disabled="loading.submit || loading.domain"
                  v-if="isTeamAvail"
                  class="mr-4"
                  outlined
                  @click="handleChangeTeam"
                  >Ubah</v-btn
                >
                <v-btn
                  color="primary"
                  :loading="loading.submit || loading.domain"
                  @click="handleCheckTeamAvail"
                >
                  <span v-if="!isTeamAvail"> Cek Ketersediaan </span>
                  <span v-else> Simpan & Lanjutkan </span>
                  <v-icon right>ri-arrow-right-line</v-icon>
                </v-btn>
              </div>
            </v-form>

            <!-- ini buat step tiga -->
            <v-form
              class="mb-6"
              v-else-if="tabActive == 2"
              ref="formData"
              lazy-validation
              :disabled="loading.submit"
            >
              <v-row>
                <v-col cols="12" lg="4">
                  <div class="mb-2 font-weight-bold">Saya</div>
                  <v-text-field
                    disabled
                    dense
                    background-color="grey lighten-3"
                    v-model="my.fullname"
                    type="text"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" lg="4">
                  <div class="mb-2 font-weight-bold">Alamat Email</div>
                  <v-text-field
                    disabled
                    dense
                    background-color="grey lighten-3"
                    v-model="my.email"
                    type="text"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" lg="4">
                  <div class="mb-2 font-weight-bold">Peran</div>
                  <v-select
                    disabled
                    dense
                    background-color="grey lighten-3"
                    v-model="my.role"
                    required
                    outlined
                    hide-details="auto"
                    :items="roles"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row v-for="(member, index) in members" :key="index">
                <v-col cols="12" lg="4">
                  <div class="mb-2 font-weight-bold">
                    Nama Lengkap Anggota
                  </div>
                  <v-text-field
                    v-model="member.fullname"
                    dense
                    :rules="[
                      ...requiredIf(
                        isFilled(member),
                        requiredRule('Nama Lengkap')
                      ),
                    ]"
                    type="text"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" lg="4">
                  <div class="mb-2 font-weight-bold">
                    Alamat Email Anggota
                  </div>
                  <v-text-field
                    v-model="member.email"
                    :rules="[
                      ...requiredIf(
                        isFilled(member),
                        requiredRule('Email'),
                        emailRule('Email')
                      ),
                    ]"
                    dense
                    type="text"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" lg="4">
                  <div class="mb-2 font-weight-bold">Peran</div>
                  <v-select
                    v-model="member.role"
                    :rules="[
                      ...requiredIf(isFilled(member), requiredRule('Peran')),
                    ]"
                    dense
                    required
                    outlined
                    hide-details="auto"
                    :items="roles"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-btn
                class="mt-4"
                text
                color="primary"
                :disabled="members.length >= 2"
                @click="members.push({})"
              >
                <v-icon>ri-add-line</v-icon>
                Tambah anggota tim
              </v-btn>
            </v-form>

            <div class="mb-4">
              <template v-if="tabActive != 1">
                <v-btn
                  color="primary"
                  :loading="loading.submit"
                  @click="handleNextStep"
                >
                  Simpan & Lanjutkan
                  <v-icon right>ri-arrow-right-line</v-icon>
                </v-btn>

                <v-btn
                  v-if="tabActive == 2"
                  class="ml-4"
                  outlined
                  @click="handleSkip"
                  >Tambahkan Nanti</v-btn
                >
              </template>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-col>
    <v-col
      class="pa-0 d-none d-md-block"
      cols="12"
      md="5"
      :style="{
        backgroundImage: `url(${BG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }"
    >
      <v-row class="h-full py-16 px-4" no-gutters justify="center">
        <v-col class="" cols="12" sm="8">
          <div class="text-center mb-6">
            <v-img class="d-inline-block" :src="ILLU" width="60%"></v-img>
          </div>

          <div class="text-center text-h5 mb-2">
            {{ section.title }}
          </div>
          <div class="text-center">
            {{ section.subtitle }}
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog v-model="showFeedback" width="480px" max-width="100vw">
      <v-card style="position: relative">
        <v-btn
          x-small
          icon
          outlined
          @click="showFeedback = false"
          style="top: 1rem; right: 1rem; position: absolute"
        >
          <v-icon size="16">ri-close-line</v-icon>
        </v-btn>

        <v-row class="pt-8" no-gutters>
          <v-col cols="12">
            <div class="text-center mb-4">
              <v-icon size="48" color="success">ri-checkbox-circle-fill</v-icon>
            </div>
            <div class="text-h6 text-center mb-4">Selamat</div>
            <div class="text-center mb-4 px-4">
              Tim telah berhasil dibuat. Kami telah mengirimkan email aktivasi
              akun ke setiap anggota yang ditambahkan. Silakan cek email
              masing-masing.
            </div>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-row class="pa-4" no-gutters justify="center">
            <v-btn color="primary" @click="handleContinue">Lanjut</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="showAlert" top text color="error">
      {{ alertMessage }}
    </v-snackbar>
  </v-row>
</template>

<script>
import LOGO from "@/assets/images/logo.png";
import BG from "@/assets/images/BG.png";
import ILL_1 from "@/assets/images/illustration-1.svg";
import ILL_2 from "@/assets/images/illustration-2.svg";
import ILL_3 from "@/assets/images/illustration-3.svg";

import * as newAuth from "@/model/new-auth";
import { mapState } from "vuex";

export default {
  data: () => ({
    tabActive: 0,
    bioData: {},
    loading: {},
    provinces: [],
    cities: [],
    domain: "",
    isTeamAvail: undefined,
    my: {},
    members: [],
    showFeedback: false,
    showAlert: false,
    alertMessage: "",
  }),
  computed: {
    ...mapState({ register_status: (state) => state.auth.register_status }),
    subdomain() {
      return process.env.VUE_APP_MAIN_DOMAIN;
    },
    LOGO: () => LOGO,
    BG: () => BG,
    ILLU() {
      switch (this.tabActive) {
        case 0:
          return ILL_1;
        case 1:
          return ILL_2;
        case 2:
          return ILL_3;
        default:
          return "";
      }
    },
    section() {
      switch (this.tabActive) {
        case 0:
          return {
            title: "Buat Profil",
            subtitle:
              "Informasi mengenai profil dan perusahaan dibutuhkan untuk pembuatan CA (Certified Authority) yang digunakan sebagai identitas digital perusahaan Anda",
          };
        case 1:
          return {
            title: "Siapkan Tim",
            subtitle:
              "Unggah dokumen dan undang tim untuk melakukan penandatanganan secara digital dari berbagai tempat, lewat satu subdomain khusus.",
          };
        case 2:
          return {
            title: "Tambah Anggota Tim",
            subtitle:
              "Undang atasan atau rekan kerja ke dalam tim Pensign untuk memulai proses penandatangan dan berbagi dokumen secara digital.",
          };

        default:
          return {};
      }
    },
    roles() {
      return [
        { text: "Admin", value: "admin" },
        { text: "User", value: "user" },
      ];
    },
  },
  mounted() {
    document.title = "Completing Register - " + process.env.VUE_APP_TITLE;
    this.getProvinces();
    this.members.push({});
    // this.tabActive = 1;
    this.tabActive = this.register_status.step - 1;
    if (this.tabActive == 2) this.getProfile();
  },
  methods: {
    isFilled(member) {
      return member.fullname || member.email || member.role;
    },
    requiredIf(condition, ...rules) {
      if (condition) return rules;
      return [() => true];
    },
    requiredRule(label = "") {
      return (v) => !!v || `${label} tidak boleh kosong`.trim();
    },
    domainRule(label = "") {
      return (v) =>
        (!!v.match(/^[A-Za-z0-9-]+$/) &&
          v.at(0) != "-" &&
          v.at(v.length - 1) != "-") ||
        `${label} tidak valid.`;
    },
    numberRule(label = "") {
      return (v) => !isNaN(v) || `${label} harus angka`.trim();
    },
    emailRule(label = "") {
      return (v) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || `${label} tidak valid.`;
      };
    },
    async getProfile() {
      newAuth
        .getProfile()
        .then((res) => res.data)
        .then((res) => {
          const { data: profile } = res;
          this.$set(this.my, "fullname", profile.fullname);
          this.$set(this.my, "email", profile.email);
          this.$set(this.my, "role", "admin");
        });
    },
    async getProvinces() {
      const {
        data: { data: provinces },
      } = await this.axios.get("econtract/master/province");
      this.provinces = provinces;
    },
    async getCities(province_id) {
      this.city_id = null;
      const {
        data: { data: cities },
      } = await this.axios.get("econtract/master/city", {
        params: { province_id: province_id },
      });
      this.cities = cities;
    },
    async createProfile() {
      const province = {
        province_id: this.bioData.province_id,
        province_name: this.provinces.find(
          (v) => v.id == this.bioData.province_id
        ).name,
      };
      const city = {
        city_id: this.bioData.city_id,
        city_name: this.cities.find((v) => v.id == this.bioData.city_id).name,
      };
      return newAuth
        .createProfile({
          ...this.bioData,
          province,
          city,
        })
        .then(() => {
          this.tabActive = 1;
          setTimeout(() => {
            this.$refs.formData.reset();
          }, 1e2);
        })
        .catch((e) => {
          let msg = e.message;
          if (e.response?.data?.msg) msg = e.response?.data?.msg;
          this.showAlert = true;
          this.alertMessage = msg;
        });
    },
    async createDomain() {
      const domain = this.domain + "." + this.subdomain;

      return newAuth
        .createDomain({ domain })
        .then((res) => res.data)
        .then((res) => {
          if (res.code != 200) throw new Error(res.msg);
          this.tabActive = 2;
          this.getProfile();
          setTimeout(() => {
            this.$refs.formData.reset();
          }, 1e2);
        })
        .catch((e) => {
          let msg = e.message;
          if (e.response?.data?.msg) msg = e.response?.data?.msg;
          this.showAlert = true;
          this.alertMessage = msg;
        });
    },
    async createTeam() {
      const members = this.members.filter(
        (member) => member.fullname && member.email && member.role
      );

      return newAuth
        .createTeam(members)
        .then(() => {
          this.showFeedback = true;
          setTimeout(() => {
            this.$refs.formData.reset();
          }, 1e2);
        })
        .catch((e) => {
          let msg = e.message;
          if (e.response?.data?.msg) msg = e.response?.data?.msg;
          this.showAlert = true;
          this.alertMessage = msg;
        });
    },
    async handleNextStep() {
      this.$set(this.loading, "submit", true);
      const isValid = await this.$refs.formData.validate();
      if (isValid) {
        switch (this.tabActive) {
          case 0:
            await this.createProfile();
            break;
          case 1:
            await this.createDomain();
            break;
          case 2:
            await this.createTeam();
            break;
          default:
            break;
        }
        await this.$store.dispatch("registerStatus");

        // if (this.tabActive == 1) {
        //   this.handleCheckTeamAvail();
        //   if (this.isTeamAvail) this.tabActive++;
        // } else if (this.tabActive == 2) this.showFeedback = true;
        // else this.tabActive++;
      }
      this.$set(this.loading, "submit", false);
    },
    async handleCheckTeamAvail() {
      const isValid = await this.$refs.formData.validate();
      if (!isValid) return;
      if (this.isTeamAvail) return this.handleNextStep();
      this.$set(this.loading, "domain", true);
      await newAuth
        .checkDomain({ domain: this.domain })
        .then((res) => res.data)
        .then((res) => {
          this.isTeamAvail = res.data.available;
        });
      this.$set(this.loading, "domain", false);
    },
    handleChangeTeam() {
      this.isTeamAvail = undefined;
    },
    handleSkip() {
      return newAuth
        .createTeam([])
        .then(async () => {
          await this.$store.dispatch("registerStatus");
          return this.handleContinue();
        })
        .catch((e) => {
          let msg = e.message;
          if (e.response?.data?.msg) msg = e.response?.data?.msg;
          this.showAlert = true;
          this.alertMessage = msg;
        });
    },
    async handleContinue() {
      await this.$store.dispatch("get_user");
      this.$router.push({ name: "starter" });
    },
  },
};
</script>

<style>
.custom-tabs.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: #4c6583 !important;
}
.custom-tabs .v-tab--disabled {
  opacity: 1 !important;
}
.custom-tabs .v-tab.v-tab:not(:last-child) {
  margin-right: 0;
}
@media (min-width: 600px) {
  .custom-tabs .v-slide-group__content {
    justify-content: space-between;
  }
}
</style>
