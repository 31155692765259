<template>
  <v-row style="min-height: 100vh">
    <v-col v-if="pensignAuth != null">
      <v-row class="py-5 px-5">Memuat...</v-row>
    </v-col>
    <v-col v-if="pensignAuth == null" class="white" cols="12" md="8" xl="9">
      <v-row justify="center" style="height: 100%">
        <v-col class="d-flex flex-column py-16 px-4" cols="12">
          <v-row justify="center">
            <v-col cols="12" xl="4" lg="6" md="8" sm="10">
              <div class="mb-16">
                <v-img :src="LOGO" height="32" contain position="left"></v-img>
              </div>
              <div class="text-h4 mb-8">
                Masuk
              </div>
              <div class="mb-16">
                <v-form
                  ref="loginForm"
                  :disabled="loading"
                  lazy-validation
                  @submit.prevent="handleLogin"
                >
                  <div class="mb-4">
                    <div class="mb-2 font-weight-bold">Alamat Email</div>
                    <v-text-field
                      v-model="email"
                      :rules="emailRequired"
                      type="email"
                    >
                    </v-text-field>
                  </div>
                  <div class="mb-4">
                    <div class="mb-2 font-weight-bold">
                      Kata Sandi
                      <span class="float-end">
                        <router-link
                          class="font-weight-medium text-decoration-none"
                          tabindex="-1"
                          color="primary"
                          :to="{ name: 'send-reset' }"
                        >
                          Lupa Kata Sandi?
                        </router-link>
                      </span>
                    </div>
                    <v-text-field
                      v-model="password"
                      :rules="passwordRequired"
                      :type="isShowPassword ? 'text' : 'password'"
                    >
                      <template #append>
                        <v-btn
                          icon
                          @click="isShowPassword = !isShowPassword"
                          style="margin-top: -12px;"
                        >
                          <v-icon>{{
                            isShowPassword ? "ri-eye-fill" : "ri-eye-off-fill"
                          }}</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </div>
                  <div class="mb-4">
                    <v-btn
                      color="primary"
                      large
                      block
                      type="submit"
                      :loading="loading"
                      >Masuk</v-btn
                    >
                  </div>

                  <div class="mb-4 text-center">
                    Belum punya akun Pensign?
                  </div>

                  <div class="mb-4">
                    <v-btn
                      outlined
                      large
                      block
                      :disabled="loading"
                      @click="$router.push({ name: 'register' })"
                      >Registrasi</v-btn
                    >
                  </div>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12 mt-auto">
          <div class="mt-auto mb-4">
            &copy; {{ year }}, Pensign . Semua Hak Dilindungi
            Undang-Undang.
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="pensignAuth == null" cols="12" md="4" xl="3">
      <v-row class="h-full" align="center">
        <m-auth-carousel></m-auth-carousel>
      </v-row>
    </v-col>

    <v-snackbar v-model="showAlert" top text color="error">
      {{ alertMessage }}
    </v-snackbar>
  </v-row>
</template>

<script>
import LOGO from "@/assets/images/logo.png";
import mAuthCarousel from "../../components/m-auth-carousel.vue";

export default {
  components: { mAuthCarousel },
  data: () => ({
    active: null,
    email: null,
    password: null,
    isShowPassword: false,
    loading: false,
    showAlert: false,
    year: null,
    alertMessage: "",
    pensignAuth: null,
  }),
  computed: {
    LOGO: () => LOGO,
    emailRequired: () => [
      (v) => !!v || "Email tidak boleh kosong",
      (v) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || "Email tidak valid.";
      },
    ],
    passwordRequired: () => [(v) => !!v || "Kata Sandi tidak boleh kosong"],
  },

  mounted() {
    document.title = "Masuk - " + process.env.VUE_APP_TITLE;
    this.year = new Date().getFullYear();
    this.pensignAuth = localStorage.getItem("pensign_auth");
  },
  methods: {
    async handleLogin() {
      const isValid = await this.$refs.loginForm.validate();
      if (isValid) {
        this.loading = true;
        try {
          const res = await this.$store.dispatch("login", { email: this.email, password: this.password });
          await this.$store.dispatch("get_user");
          this.redirectAfterLogin(res.data.account.is_reset);
          this.loading = false;
          this.submiting = false;
        } catch(e) {
          console.log('[err] handle login', e);
          let msg = e.message;
          if (e.response?.data?.msg) msg = e.response?.data?.msg;
          this.showAlert = true;
          this.alertMessage = msg;
          this.loading = false;
          this.submiting = false;
        }
      }
    },

    redirectAfterLogin(reset) {
      if (reset) {
        return this.$router.push("/update-password");
      } else if (localStorage.getItem("pensign_redirect") != null) {
        this.$router.push(localStorage.getItem("pensign_redirect"));
        localStorage.removeItem("pensign_redirect");
      } else {
        this.$router.push("/dashboard");
      }
    },
  },
};
</script>
