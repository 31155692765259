require('./lib');

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './lib/vuetify';
import VueCompositionAPI from '@vue/composition-api';
import VueTour from 'vue-tour';
Vue.use(VueCompositionAPI);
const moment = require('moment');

require('vue-tour/dist/vue-tour.css');

Vue.use(VueTour);
Vue.use(require('vue-moment'), {
  moment,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
