<template>
  <v-col>
    <v-carousel
      v-model="active"
      continuous
      cycle
      :show-arrows="false"
      hide-delimiters
      hide-delimiter-background
      height="240"
    >
      <v-carousel-item v-for="item in items" :key="item.id" :value="item.id">
        <v-row class="fill-height" align="center" justify="center">
          <v-col>
            <div class="text-center font-italic mx-16 mb-8">
              "{{ item.content }}"
            </div>
            <div class="text-center mb-2">
              {{ item.name }}
            </div>
            <div class="text-center font-weight-bold mb-4">
              {{ item.title }} 
            </div>
            <div class="text-center">
              <v-img height="32" contain :src="item.logo"></v-img>
            </div>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>

    <v-row class="my-4" justify="center">
      <a
        href="#"
        v-for="item in items"
        :key="item.id"
        class="mx-1"
        @click="active = item.id"
      >
        <v-icon :color="item.id == active ? 'primary' : 'grey'" size="10"
          >ri-checkbox-blank-circle-fill</v-icon
        >
      </a>
    </v-row>
  </v-col>
</template>

<script>
import aditifLogo from "@/assets/images/company-aditif.png";
import sosiagoLogo from "@/assets/images/company-sosiago.png";
import rucikaLogo from "@/assets/images/company-rucika.png";

export default {
  data: () => ({
    active: 1,
  }),
  computed: {
    items() {
      return [
        {
          id: 1,
          company: "Aditif",
          logo: aditifLogo,
          name: "Saga Iqranegara",
          title: "Ketua Umum",
          content:
            "Pensign menghemat waktu untuk berbagi dokumen ke para stakeholder dan anggota digital kreatif kami.",
        },
        {
          id: 2,
          company: "SosiaGO",
          logo: sosiagoLogo,
          name: "Harimurti Restyadanov",
          title: "Direktur",
          content:
            "Dengan Pensign, hanya butuh kurang dari 2 menit untuk mensirkulasikan dokumen ke semua stakeholder.",
        },
        {
          id: 3,
          company: "Rucika",
          logo: rucikaLogo,
          name: "Hery Gunawan",
          title: "Accounting",
          content:
            "Pensign telah membantu tim sales kami menjadi lebih praktis dalam urusan administratif dengan para klien kami.",
        },
      ];
    },
  },
};
</script>
