<template>
  <v-row align-content="center" class="white">
    <v-col class="py-0 mx-auto" md="12" style="min-height: 100vh;padding:0;">
      <v-row style="margin:0;">
        <v-col class="pa-0" md="6" style="min-height: 100vh;background: rgba(0, 104, 214, 1);">
          <div
            :style="{
              backgroundImage: `url('${require('@/assets/images/bg-login.png')}')`,
            }"
            style="background-size: cover;"
          >
            <v-spacer class="py-16"></v-spacer>
            <v-card class="transparent px-10">
              <img src="@/assets/images/logo-white.png" class="mb-3" />
              <p class="text-white font-weight-bold title-bg-login py-8 px-2">
                Solution for your digital document mobility.
              </p>
              <v-card
                class="mx-auto px-5"
                style="border: 1px solid #fff;border-radius: 8px;background: transparent;"
                outlined
              >
                <v-card-title class="text-h6 font-weight-bold text-left pe-5">
                  <span class="white--text">Langkah Mudah Menggunakan Pensign</span>
                </v-card-title>

                <v-window v-model="step">
                  <v-window-item :value="1">
                    <v-card-text class="text-center" style="min-height: 260px;">
                      <img src="@/assets/images/slide-1.png" />
                      <p class="text-subtitle-1 white--text text--darken-1 font-weight-medium">
                        Daftar dan buat akun <br />Pensign Anda
                      </p>
                    </v-card-text>
                  </v-window-item>

                  <v-window-item :value="2">
                    <v-card-text class="text-center" style="min-height: 260px;">
                      <img src="@/assets/images/slide-2.png" />
                      <p class="text-subtitle-1 white--text text--darken-1 font-weight-medium">
                        Lengkapi data perusahaan Anda
                      </p>
                    </v-card-text>
                  </v-window-item>

                  <v-window-item :value="3">
                    <v-card-text class="text-center" style="min-height: 260px;">
                      <img src="@/assets/images/slide-3.png" />
                      <p class="text-subtitle-1 white--text text--darken-1 font-weight-medium">
                        Buat subdomain Pensign
                      </p>
                    </v-card-text>
                  </v-window-item>

                  <v-window-item :value="4">
                    <v-card-text class="text-center" style="min-height: 260px;">
                      <img src="@/assets/images/slide-4.png" />
                      <p class="text-subtitle-1 white--text text--darken-1 font-weight-medium">
                        Atur pengguna Pensign melalui <br />
                        sistem Pensign
                      </p>
                    </v-card-text>
                  </v-window-item>

                  <v-window-item :value="5">
                    <v-card-text class="text-center" style="min-height: 260px;">
                      <img src="@/assets/images/slide-5.png" />
                      <p class="text-subtitle-1 white--text text--darken-1 font-weight-medium">
                        Lakukan aktivitas penandatanganan di <br />
                        subdomain Pensign
                      </p>
                    </v-card-text>
                  </v-window-item>
                </v-window>

                <!-- <v-divider></v-divider> -->

                <v-card-actions class="px-0">
                  <div class="row">
                    <div id="btn-slide" class="col-md-9">
                      <v-btn
                        :disabled="step === 1"
                        @click="step = 1"
                        class="white--text font-weight-bold"
                        text
                        small
                      >
                        1
                      </v-btn>
                      <v-btn
                        :disabled="step === 2"
                        @click="step = 2"
                        class="white--text font-weight-bold"
                        text
                        small
                      >
                        2
                      </v-btn>
                      <v-btn
                        :disabled="step === 3"
                        @click="step = 3"
                        class="white--text font-weight-bold"
                        text
                        small
                      >
                        3
                      </v-btn>
                      <v-btn
                        :disabled="step === 4"
                        @click="step = 4"
                        class="white--text font-weight-bold"
                        text
                        small
                      >
                        4
                      </v-btn>
                      <v-btn
                        :disabled="step === 5"
                        @click="step = 5"
                        class="white--text font-weight-bold"
                        text
                        small
                      >
                        5
                      </v-btn>
                    </div>
                    <div class="col-md-3 text-center mx-auto">
                      <v-btn @click="getWa()" text class="white--text ">
                        <v-icon class="mr-1">ri-phone-line</v-icon> Bantuan
                      </v-btn>
                    </div>
                  </div>
                  <!-- <v-spacer></v-spacer> -->
                </v-card-actions>
              </v-card>
              <div class="text-center d-lg-none d-md-none d-sm-block mt-5">
                <v-btn rounded color="white" outlined large to="/#login">
                  Login
                </v-btn>
                <p class="py-3"><v-icon color="white">ri-arrow-down-circle-fill</v-icon></p>
              </div>
            </v-card>
          </div>
        </v-col>
        <v-col id="login" class="white" md="6" style="min-height: 100vh">
          <v-spacer class="py-16"></v-spacer>
          <v-form ref="form" class="px-8 col-md-8 mx-auto" @submit.prevent="submit" v-model="valid">
            <div class="mx-auto text-left mb-3 text-black">
              <p class="font-weight-bold title-login">Login ke Akun Anda</p>
              <p class="text-body-1">
                Silahkan masukkan email & kata sandi untuk masuk ke akun Anda
              </p>
              <!-- <img src="@/assets/images/logo-yec.png" class="me-2"> -->
            </div>
            <v-alert type="error" text dense prominent v-show="response !== null">{{
              response
            }}</v-alert>
            <v-row>
              <v-col>
                <div class="subtitle-1 mb-2 text-black mt-3">Email</div>
                <v-text-field
                  filled
                  v-model="email"
                  :rules="[...rules('Alamat email', 'required'), validate]"
                  required
                  outlined
                  type="email"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div class="mb-2">
                  <span class="subtitle-1 text-black">Kata Sandi</span>
                </div>
                <v-text-field
                  filled
                  v-model="password"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show"
                  outlined
                  hide-details="auto"
                  :rules="rules('Kata sandi', 'required')"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-checkbox v-model="checkbox" label="Ingat saya" class="mt-0"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-btn large elevation="0" type="submit" color="primary" :loading="submiting" block>
                  Masuk
                </v-btn>
              </v-col>
              <v-col cols="12" class="text-center">
                <router-link
                  class="font-weight-medium text-decoration-none"
                  tabindex="-1"
                  color="primary"
                  :to="{ name: 'send-reset' }"
                >
                  Lupa Kata Sandi?
                </router-link>
              </v-col>
            </v-row>
            <v-spacer class="py-8"></v-spacer>
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="text-black">
                  Tidak mempunyai akun?
                  <router-link
                    class="font-weight-medium text-decoration-none"
                    tabindex="-1"
                    color="primary"
                    :to="{ name: 'registration' }"
                  >
                    Daftar
                  </router-link>
                </span>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import validationMixin from '../_mixins/validation';

export default {
  mixins: [validationMixin],
  name: 'login',
  data() {
    return {
      valid: true,
      errors: null,
      email: '',
      password: '',
      checkbox: false,
      submiting: false,
      show: false,
      response: null,
      emailRules: [
        (v) => !!v || 'Email tidak boleh kosong',
        (v) => /.+@.+\..+/.test(v) || 'Email tidak valid',
      ],
      step: 1,
    };
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return 'Sign-up';
        case 2:
          return 'Create a password';
        default:
          return 'Account created';
      }
    },
  },
  methods: {
    getWa() {
      window.open('https://wa.me/6285795641917');
    },
    submit() {
      this.errors = null;

      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.submiting = true;
          this.email = this.email.trim();
          let email = this.email;
          let password = this.password;

          this.$store
            .dispatch('login', { email, password })
            .then((res) => {
              this.submiting = false;
              // console.log("res",res)
              if (res.code === 200) {
                this.$store.dispatch('get_user');
                this.redirectAfterLogin(res.data.account.is_reset);
                this.$root.$emit('setDialog', true);
              } else {
                this.response = res.msg;
                setTimeout(() => {
                  this.response = null;
                }, 3000);
                // this.$refs.form.reset()
              }
            })
            .catch((error) => {
              this.submiting = false;
              // console.log(error.response)
              this.response = error.response.data.msg;
              setTimeout(() => {
                this.response = null;
              }, 3000);
            });
        }
      }, 50);
    },
    redirectAfterLogin(reset) {
      if (localStorage.getItem('pensign_redirect') != null) {
        this.$router.push(localStorage.getItem('pensign_redirect'));
        localStorage.removeItem('pensign_redirect');
      } else {
        if (reset == true) {
          this.$router.push('/update-password');
        } else {
          this.$router.push('/dashboard');
        }
      }
    },
  },
};
</script>

<style>
#btn-slide .v-btn.v-btn--disabled {
  background: #fff;
}
</style>
