<!-- @format -->

<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold"
      >Certificate</v-card-title
    >

    <v-card-text v-if="!isLoading && !hasError" class="px-0">
      <div class="content">
        <div class="table my-3">
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">
              <v-row align="center">
                <v-col lg="6">
                  <p class="fs-16 text-black font-weight-medium">
                    {{ certificate.title }}
                  </p>
                </v-col>

                <v-col
                  id="search"
                  lg="6"
                  class="justify-end align-end text-right"
                >
                  <v-btn
                    class="text-right"
                    color="primary"
                    elevation="0"
                    :to="{
                      name: 'create-certificate-receive',
                      params: {id: certificate.id || 0},
                    }"
                  >
                    Create Certificate
                  </v-btn>
                </v-col>
              </v-row>
              <div class="col-md-12 px-0 py-4">
                <v-divider></v-divider>
              </div>
              <div class="row">
                <div class="col-md-4 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-0">
                      <v-list three-line>
                        <v-list-item class="pl-0">
                          <img
                            :src="certificate.preview"
                            class="img-table mr-1 ms-0"
                          />
                          <v-list-item-content>
                            <p class="text-semu fs-12 mb-2 wb">
                              CERTIFICATE TITLE
                            </p>
                            <p class="fs-14 text-black font-weight-medium wb">
                              {{ certificate.title }}
                            </p>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </div>
                <v-divider vertical></v-divider>
                <div class="col-md-2 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-5">
                      <p class="text-semu fs-12 mb-2 wb">ISSUANCE DATE</p>
                      <p class="fs-14 text-black font-weight-medium wb">
                        {{
                          certificate.created_at | moment("dddd, D MMMM YYYY")
                        }}
                      </p>
                    </v-card-text>
                  </v-card>
                </div>
                <v-divider vertical></v-divider>
                <div class="col-md-2 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-5">
                      <p class="text-semu fs-12 mb-2 wb">PENERIMA</p>
                      <p class="fs-14 text-black font-weight-medium wb">
                        {{ certificate.recipient }} Penerima
                      </p>
                    </v-card-text>
                  </v-card>
                </div>
                <v-divider vertical></v-divider>
                <div class="col-md-2 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-5">
                      <p class="text-semu fs-12 mb-2 wb">LAST UPDATED</p>
                      <p class="fs-14 text-black font-weight-medium wb">
                        {{
                          certificate.updated_at | moment("dddd, D MMMM YYYY")
                        }}
                      </p>
                    </v-card-text>
                  </v-card>
                </div>
                <v-divider vertical></v-divider>
                <div class="col-md-2 px-1">
                  <v-card rounded="lg" class="transparent px-0">
                    <v-card-text class="py-5">
                      <v-btn
                        @click="preview = true"
                        block
                        outlined
                        color="primary"
                        class="mb-2"
                        >
                          Preview
                        </v-btn
                      >
                      <v-dialog v-model="preview" max-width="60%">
                        <v-card class="d-flex justify-center items-center">
                          <img
                            style="width: 100%; height: 100%; object-fit: cover;"
                            :src="certificate.preview"
                            alt="image"
                          />
                        </v-card>
                      </v-dialog>
                      <v-btn
                        :to="{
                          name: 'edit-template',
                          params: {
                            id: certificate.id,
                          },
                        }"
                        block
                        outlined
                        color="primary"
                        class=""
                        >Edit</v-btn
                      >
                    </v-card-text>
                  </v-card>
                </div>
              </div>
              <div class="col-md-12 px-0 py-4">
                <v-divider></v-divider>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <v-text-field
                    outlined
                    append-icon="$search"
                    class="mt-1 ms-2 mx-width-85"
                    placeholder="Search"
                    solo
                    flat
                    v-model="search"
                  ></v-text-field>
                </div>
                <div class="col-md-6 text-end align-end">
                  <v-text-field
                    outlined
                    append-icon="ri-filter-fill"
                    class="mt-1 ms-2 mx-width-85 float-right"
                    placeholder="Filter"
                    solo
                    flat
                  ></v-text-field>
                </div>
              </div>
            </v-card-title>
            <div v-if="!recipient" class="col-md-6 mx-auto my-2">
              <img src="@/assets/images/certif-empty.svg" />
              <p class="font-weight-bold text-black mb-2">Sertifikat Kosong</p>
              <p class="text-semu">
                Buat sertifikat baru atau coba persempit pencarian jika anda
                tidak dapat menemukan sertifikat yang anda cari
              </p>
            </div>
            <v-data-table
              v-else
              :headers="headers"
              :items="recipient"
              :search="search"
            >
              <template v-slot:[`item.status`]="{item}">
                <v-chip
                  v-if="item.status == 'issuance'"
                  color="success"
                  outlined
                  small
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else
                  :color="item.status == 'Prececced' ? '#F0AB0B' : '#2665DE'"
                  outlined
                  small
                >
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {onMounted, reactive, toRefs} from "@vue/composition-api";
import useFetch from "../../../hooks/useFetch";
import api from "../../../store/api";
export default {
    created() {
    document.title = "Detail Certificate-Pensign Admin";
  },
  data: () => ({
    search: "",
    headers: [
      {text: "NAME", value: "name"},
      {text: "EMAIL", value: "email"},
      {text: "ISSUANCE DATE", value: "issuanceDate"},
      {text: "PHONE NUMBER", value: "mobilephone"},
      {text: "EXPIRATION DATE", value: "expirationDate"},
      {text: "STATUS", value: "status"},
    ],
  }),
  setup(props, ctx) {
    const {fetchData} = useFetch();

    const state = reactive({
      certificate: null,
      recipient: [],
      isLoading: true,
      hasError: false,
      preview: false,
      items: [
        {
          text: "E-Certificate",
          disabled: false,
          href: "#",
        },
        {
          text: "Certificate",
          disabled: false,
          href: "/certificate",
        },
        {
          text: "",
          disabled: true,
          href: "#",
        },
      ],
    });

    const route = ctx.root._route;
    onMounted(async () => {
      state.isLoading = true;
      let data = await fetchData({
        url: api.certificate_list + '/' + route.params.id,
      });
      let recipient = await fetchData({
        url: api.recipient_list + route.params.id + '?limit=10000',
        method: "get",
      });
      if (!data.hasError && !recipient.hasError) {
        state.certificate = data.response.data.certificate;
        state.recipient = recipient.response.data.recipient;
        state.items[2].text = state.certificate.title;
      }
      state.isLoading = false;
    });
    return {
      props,
      ...toRefs(state),
    };
  },
};
</script>

<style></style>
