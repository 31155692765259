var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"transparent",attrs:{"rounded":"lg"}},[_c('v-breadcrumbs',{staticClass:"px-0",attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("ri-arrow-right-s-line")])]},proxy:true}])}),_c('v-card-title',{staticClass:"text-black pt-0 px-0 fs-20 font-weight-bold"},[_vm._v("Disbursement")]),_c('v-card-text',{staticClass:"px-0"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"table my-3"},[_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',{staticClass:"font-weight-regular"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('p',{staticClass:"fs-16 text-black font-weight-medium"},[_vm._v("List of Transaction")])]),_c('div',{staticClass:"col-md-6 text-right"},[_c('v-btn',{staticClass:"white--text ma-1",attrs:{"color":"primary","to":{ name: 'schedule-create'}}},[_vm._v(" Create Scheduled Disbursement ")])],1)]),_c('div',{staticClass:"col-md-12 px-0"},[_c('v-divider')],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 px-0"},[_c('div',{staticClass:"d-flex"},[_c('v-select',{staticClass:"ma-1",attrs:{"items":_vm.ex,"item-value":"value","item-text":"label","placeholder":"Select Status"},on:{"change":function($event){return _vm.getListScheduleDisbursment(_vm.columnFilter.ex)}},model:{value:(_vm.columnFilter.status),callback:function ($$v) {_vm.$set(_vm.columnFilter, "status", $$v)},expression:"columnFilter.status"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"elevation":"0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"placeholder":"Start date - End date","append-icon":"ri-calendar-line","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"min":"1950-01-01","outlined":"","required":"","range":""},on:{"change":function($event){return _vm.getListScheduleDisbursment(_vm.columnFilter.date)}},model:{value:(_vm.columnFilter.date),callback:function ($$v) {_vm.$set(_vm.columnFilter, "date", $$v)},expression:"columnFilter.date"}})],1)],1)]),_c('div',{staticClass:"col-md-6 px-0 align-end text-end"},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mt-1 ms-2 mx-width-85",attrs:{"outlined":"","append-icon":"$search","placeholder":"Search","solo":"","flat":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"white--text ma-1",staticStyle:{"min-height":"48px"},attrs:{"color":"grey darken-4"},on:{"click":function($event){_vm.dialog=true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("ri-download-cloud-fill ")]),_vm._v(" Export ")],1)],1)])])]),(!_vm.listTransaction)?_c('div',{staticClass:"col-md-6 mx-auto my-2"},[_c('img',{attrs:{"src":require("@/assets/images/empty.png")}}),_c('p',{staticClass:"font-weight-bold text-black mb-2"},[_vm._v("No Disbursement transaction found.")]),_c('p',{staticClass:"text-semu"},[_vm._v("All transaction of your Pensign account will appear here.")]),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","outlined":""}},[_vm._v(" Create Schedule Disbursement ")])],1):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listTransaction,"search":_vm.search,"loading":_vm.isLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.approved_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseTime(item.approved_at))+" ")]}},{key:"item.total_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.total_amount))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'schedule-detail', params: { id: item.id } }}},[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'canceled')?_c('v-chip',{staticClass:"text-capitalize",attrs:{"color":"#8B8E94","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")]):(item.status === null)?_c('v-chip',{attrs:{"color":"#455A64","outlined":""}},[_vm._v(" - ")]):_c('v-chip',{staticClass:"text-capitalize",attrs:{"color":item.status == 'approved' ? 'success' : '#F0AB0B',"outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)})],1)],1)])]),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Export Transaction History Report ")]),_c('v-card-text',{staticClass:"my-3"},[_c('p',{staticClass:"mb-0"},[_vm._v("Select File Type")]),_c('v-select',{staticClass:"ma-1",attrs:{"items":_vm.ex,"placeholder":"Select Type"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-center",attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Export ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }