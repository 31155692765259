<template>
  <v-card rounded="lg" class="transparent">
    <v-row class="mx-0" v-if="isLoadingInventory">
      <v-col>
        <v-card class="pa-4" outlined>
          <div class="text-h5 mb-4 black--text">Informasi Stok</div>
          <div class="d-flex flex-nowrap mb-4">
            Memuat...
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-0" v-if="!isLoadingInventory">
      <v-col cols="12">
        <v-card class="pa-4" outlined>
          <div class="text-h5 mb-4 black--text">Informasi Stok</div>
          <div class="d-flex flex-nowrap mb-4">
            <div v-for="item in headers" :key="item.key" class="flex-grow-1">
              <div class="mb-1">{{ item.label }}</div>
              <div>
                <span class="black--text text-h5 mr-1">{{ item.stock }}</span>
                <span class="font-weight-medium" :style="{ color: item.color }">
                  {{ item.unit }}
                </span>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card outlined>
          <v-row class="pa-4">
            <v-col cols="12">
              <div class="text-h5 mb-4 black--text">Tambah Stok</div>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="d-flex align-center mb-4">
                <img class="mr-3" height="56" :src="icons[0]" />
                <div>
                  <div class="font-weight-bold">{{ product.unit }}</div>
                  <div>{{ formatIdr(product.price) }}</div>
                </div>
              </div>

              <div>
                <ul
                  class="pricing-card-feature-list"
                  style="border-top: 0px;padding: 0px!important;"
                >
                  <li
                    v-for="(facilities, index) in product.description_company"
                    :key="index"
                    class="pricing-card-feature-item"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="pricing-card-feature-item__icon"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <p class="pricing-card-feature-item__title text-capitalize">
                      {{ facilities }}
                      <!-- {{ facilities.name }}, {{ facilities.price }} -->
                    </p>
                  </li>
                </ul>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div>
                <p class="mb-2 black--text font-weight-bold">
                  Jenis Token
                </p>

                <v-select
                  v-model="formData.type"
                  :disabled="isLoading"
                  class="mb-4"
                  :items="products"
                  item-value="id"
                  item-text="name"
                ></v-select>

                <v-alert color="#F1F5F9" dense class="text-body-1">
                  Biaya platform per item: Rp
                  {{ product.platform_fee.toLocaleString("ID") }}
                </v-alert>

                <!-- <v-divider></v-divider> -->

                <p class="black--text font-weight-bold mb-2">
                  Pilih Jumlah {{ product.unit }}
                </p>
                <v-alert
                  dense
                  outlined
                  type="error"
                  style="border: 0px solid !important; background: #f2faff !important"
                  class="mt-3 text-body-1"
                  color="#94A3B8"
                >
                  Minimal pembelian 10 {{ product.unit }}
                </v-alert>
                <v-container class="pa-1">
                  <v-row>
                    <v-col
                      v-for="(item, i) in items"
                      :key="`item-${i}`"
                      cols="4"
                      md="4"
                      sm="6"
                    >
                      <v-item>
                        <v-btn
                          color="primary"
                          dark
                          :disabled="isLoading"
                          :outlined="formData.qty != item.value && !isLoading"
                          @click="formData.qty = item.value"
                          block
                          class="rounded-lg"
                        >
                          {{ item.value }}
                        </v-btn>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
                <div
                  class="pricing-card-counter-price"
                  style="align-items: center"
                >
                  <p class="text-body-1 font-weight-medium text-muted">
                    Atau Masukkan Jumlah Token disini
                  </p>
                  <br />
                  <div
                    class="d-flex align-top"
                    style="margin: 0 auto; text-align: center"
                  >
                    <v-btn
                      min-width="40"
                      width="40"
                      height="40"
                      @click="formData.qty--"
                      :disabled="formData.qty === 0 || isLoading"
                    >
                      <v-icon>ri-subtract-line</v-icon>
                    </v-btn>
                    <v-text-field
                      :disabled="isLoading"
                      class="w-full-important"
                      type="number"
                      v-model.number="formData.qty"
                      min="10"
                      placeholder="Minimal 10 token"
                      :rules="[numberRule]"
                    >
                    </v-text-field>
                    <v-btn
                      min-width="40"
                      width="40"
                      :disabled="isLoading"
                      height="40"
                      @click="formData.qty++"
                    >
                      <v-icon>ri-add-line</v-icon>
                    </v-btn>
                  </div>
                </div>

                <!-- <v-divider></v-divider> -->
                <div class="pricing-card-price my-4">
                  <h3
                    v-if="formData.qty > 10000"
                    class="primary--text font-weight-light"
                  >
                    Untuk pembelian lebih dari 10.000 token, silahkan hubungi
                    customer service kami.
                  </h3>
                  <template v-else-if="formData.qty > 0">
                    <v-row class="mb-2" no-gutters justify-sm="space-between">
                      <v-col>{{ formData.qty }} {{ product.unit }}</v-col>
                      <v-col class="text-right">
                        {{ formData.qty }} x
                        {{ formatIdr(product.price) }}</v-col
                      >
                    </v-row>
                    <v-row class="mb-2" no-gutters justify-sm="space-between">
                      <v-col>Biaya platform</v-col>
                      <v-col class="text-right"
                        >{{ formData.qty }} x
                        {{ formatIdr(product.platform_fee) }}</v-col
                      >
                    </v-row>
                    <v-divider class="mb-2" />
                    <v-row
                      class="text-h6"
                      no-gutters
                      justify-sm="space-between"
                    >
                      <v-col>Total</v-col>
                      <v-col class="text-right">
                        {{
                          formatIdr(
                            (product.price + product.platform_fee) *
                              formData.qty
                          )
                        }}
                      </v-col>
                    </v-row>
                  </template>
                </div>
              </div>

              <v-divider></v-divider>

              <v-btn
                v-if="formData.qty > 10000"
                class="pricing-card-action__button"
                width="100%"
                height="90px"
                color="primary"
                @click="linkWa()"
              >
                Hubungi CS
              </v-btn>
              <v-btn
                v-else
                class="pricing-card-action__button"
                width="100%"
                height="90px"
                color="primary"
                @click="handleClick(formData)"
                :disabled="formData.qty < 10"
                :loading="isLoading"
              >
                Beli
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="hasError">
      {{ message }} <br />
      <p class="text-subtitle-2">- {{ messageValidation }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="hasError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="hasFormError">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="hasFormError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { reactive, ref, toRefs } from "@vue/composition-api";
import { formatPrice } from "../../../lib/formatDate";
import useFetch from "../../../hooks/useFetch";
import api from "../../../store/api";
import IMG_PENSIGN from "@/assets/images/logo-sm.png";

export default {
  data() {
    return {
      hasError: false,
      hasFormError: false,
      messageValidation: "",
      message: "",
      products: [],
      timeoutRef: null,
      icons: {
        0: IMG_PENSIGN,
      },
      headersColor: ["#0067D6", "#0067D6"],
      headers: [],
      formData: {
        type: "",
        qty: 0,
      },
      report: {},
      emet: {},
      numberRule: (v) => {
        if (!v) return "Tidak boleh kosong";
        // if (!isNaN(parseFloat(v)) && v >= 1) return true;
        if (!isNaN(parseFloat(v)) && v >= 10) return true;
        return "Pembelian kurang dari 10";
      },
      items: [
        { value: 10 },
        { value: 25 },
        { value: 50 },
        { value: 100 },
        { value: 500 },
        { value: 1000 },
      ],
      isLoading: false,
    };
  },
  computed: {
    product() {
      // return this.products;
      return this.products.find((v) => v.id == this.formData.type);
    },
  },
  setup() {
    const state = reactive({
      name: "Beli Produk e-Contract",
      title: "Pilih Paket yang anda inginkan",
      pricings: [],
      products: [],
      isLoading: false,
      isLoadingInventory: false,
      addToCartDisabled: false,
      tabs: "all",
      createOrder: {
        product_id: 0,
        qty: 0,
      },
      submitting: false,
      selectedItem: {},
      message: "",
      messageValidation: "",
      hasError: false,
      hasFormError: false,
    });

    const reload = ref(true);

    const changeTab = (val) => {
      state.tabs = val;
    };

    const addToCart = (id) => {
      state.selectedItem = state.pricings[id];
      if (!state.selectedItem.qty) {
        state.selectedItem.qty = 1;
      } else {
        state.selectedItem.qty += 1;
      }
      state.pricings[id] = state.selectedItem;
    };

    const reduceToCart = (id) => {
      state.selectedItem = state.pricings[id];
      state.selectedItem.qty -= 1;
      state.pricings[id] = state.selectedItem;
    };

    const formatToPrice = (value) => {
      return formatPrice(value);
    };

    return {
      formatToPrice,
      changeTab,
      addToCart,
      reduceToCart,
      ...toRefs(state),
      reload,
    };
  },
  unmounted() {
    clearInterval(this.timeoutRef);
  },
  async mounted() {
    this.isLoading = true;
    await this.getDataInventory();
    await this.getReport();
    this.timeoutRef = setInterval(() => {
      this.getReport();
    }, 30000);
    this.isLoading = false;
  },
  methods: {
    async handleClick() {
      const { fetchData } = useFetch();

      this.isLoading = true;

      let res = await fetchData({
        url: `econtract/inventory/client/order_v2`,
        method: "POST",
        data: {
          product_id: this.formData.type,
          qty: this.formData.qty,
        },
      }).finally(() => {
        this.isLoading = false;
      });

      if (!res.hasError) {
        this.message = "Berhasil checkout";
        let url = res.response.data.invoice.invoice_url;
        window.open(url, '_blank').focus();
      } else {
        this.hasError = true;
        this.message = res.response.data.msg;
        this.messageValidation = res.response.data.error[0].message;
      }
    },
    async getDataInventory() {
      const { fetchData } = useFetch();

      this.isLoadingInventory = true;
      const data = await fetchData({
        url: api.all_inventory,
        method: "get",
      });

      if (!data.hasError) {
        const resData = data?.response?.data ?? [];
        this.products = resData.filter((item) => {
          return item?.unit === 'ESGN';
        });      
        const dataArr = []; 
        for(const productIndex in this.products) {
          const productItem = this.products[productIndex];
          const productKey = productItem?.unit?.replaceAll(' ', '_');
          const dataProduct = {
            key: productKey,
            label: productItem?.name,
            stock: 0,
            color: this.headersColor[productIndex % 2],
            unit: productItem?.unit,
          };
          if(productItem?.unit === 'ESGN') {
            dataArr.push(dataProduct);
          }
        }
        this.headers = dataArr;
        this.formData.type = this.products[0].id;
        if (this.$route.query.product_id) this.formData.type = this.$route.query.product_id
      }
      this.isLoadingInventory = false;
    },
    formatIdr(number) {
      try {
        return parseInt(number).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        });
      } catch (error) {
        return error.message;
      }
    },
    getReport() {
      this.axios
        .get(`/econtract/web_app/client_balance_summary`)
        .then((res) => {
          const { data } = res.data;
          const dataArr = [];
          this.headers.map((item) => {
            const itemKey = item?.unit?.replaceAll(' ', '_');
            dataArr.push({
              ...item,
              ...data[itemKey],
            });
          });
          this.headers = dataArr;
        });
    },
    linkWa() {
      window.open("https://wa.me/6285795641917", "_blank");
    },
  },
};
</script>

<style scoped>
.w-full-important .v-input__slot {
  background: red !important;
}
</style>

<style scoped lang="scss">
p {
  margin: 0;
  padding: 0;
}
.pricing-wrapper {
  padding: 0 10px;
  margin: 40px 0;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  @media (min-width: 768px) {
    gap: 43px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.pricing {
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    &__tabs {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 400px;
      li {
        font-size: small;
      }
    }
  }
  &-card {
    border-radius: 12px;
    padding: 20px;
    border: solid 1px #e0e0e0 !important;
    &-header {
      display: flex;
      place-items: center;
      padding: 0 0 20px 0;
      // padding: 20px 0;
      padding-top: 10;
      &__img {
        margin-right: 0.5rem;
      }
      &__title {
        font-size: 1rem;
      }
      &__price {
        font-size: 0.5rem;
        font-weight: bold;
      }
    }
    &-feature {
      &-list {
        border-top: solid #e0e0e0 1px;
        list-style: none;
        min-height: 80px;
        padding: 20px 0 !important;
      }
      &-item {
        display: flex;
        place-items: center;
        padding: 5px;
        &__title {
          margin: 0;
          margin-bottom: 0 !important;
        }
        &__icon {
          margin-right: 5px;
          width: 20px;
          color: #9ad4fb;
        }
      }
    }
    &-counter-price {
      padding: 20px 0;
      width: 100%;
      /*display: flex;*/
      justify-content: space-between;
    }
    &-action {
      padding: 20px 0;
      &__button {
        height: 44px !important;
        border-radius: 6px;
      }
    }
  }
}

.card-header__item-group {
  box-shadow: unset !important;
}

@media (max-width: 768px) {
  .card-header__item-group {
    grid-template-columns: auto !important;
    padding: 31px 10px !important;
  }
}
</style>
