<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">
      <div class="row">
        <div class="col-md-6">
          <p class="text-black pt-0 px-0 fs-20 font-weight-bold mb-0 pb-0">
            Pengguna
          </p>
        </div>
      </div>
    </v-card-title>

    <v-card-text class="px-0">
      <div>
        <div class="table">
          <v-card>
            <v-card-title class="font-weight-regular">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <!-- <div class="col-md-6 px-0">
                      <v-select
                        v-model="columnFilter.status"
                        :items="ex"
                        :item-value="`value[0]`"
                        item-text="label"
                        placeholder="Select Status"
                        @change="getListDeposit(columnFilter.ex)"
                        class="ma-1"
                      ></v-select>
                    </div> -->
                    <div class="col-md-6 px-0">
                      <v-text-field
                        outlined
                        v-model="search"
                        append-icon="$search"
                        class="mt-1 ms-2"
                        placeholder="Cari"
                        solo
                        flat
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 text-right">
                  <div class="row">
                    <div class="col-md-12 px-0 text-right"></div>
                  </div>
                  <v-btn
                    v-if="dataCount.total_user < dataCount.max_user"
                    color="primary"
                    class="white--text ma-1"
                    @click="dialog = true"
                    style="min-height: 48px;"
                  >
                    Tambah Pengguna Baru
                  </v-btn>
                </div>
              </div>
            </v-card-title>

            <div v-if="!listUser" class="col-md-6 mx-auto my-2">
              <img src="@/assets/images/empty.png" />
              <p class="font-weight-bold text-black mb-2">No data available.</p>
            </div>

            <v-data-table
              v-else
              :headers="headers"
              :items="listUser"
              :search="search"
              :loading="isLoading"
              loading-text="Loading... Please wait"
            >
              <template #item="{item}">
                <tr v-if="false">
                  <td colspan="7">
                    <pre>{{ item }}</pre>
                  </td>
                </tr>
                <tr
                  :class="!item.is_display_subscription ? 'grey lighten-3' : ''"
                  :style="
                    !item.is_display_subscription
                      ? 'cursor: not-allowed; opacity: .5'
                      : ''
                  "
                >
                  <td>
                    {{ item.fullname }} <br />
                    {{ item.email }}
                  </td>
                  <td>{{ item.created_at }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.roles.title }}</td>
                  <td>
                    <div v-if="item.verified_at === null">
                      <v-chip small color="info">
                        Tidak
                      </v-chip>
                    </div>
                    <div v-else>
                      <v-chip small color="success">
                        Ya
                      </v-chip>
                    </div>
                  </td>
                  <td>
                    <v-btn
                      :disabled="!item.is_display_subscription"
                      v-if="user.account.roles.name === 'superadmin'"
                      class="text-center my-3"
                      text
                      small
                      color="primary"
                      @click="getPass(item.id)"
                    >
                      Perbarui kata sandi
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      v-if="
                        item.verified_at === '' ||
                          item.verified_at === null ||
                          item.verified_at == undefined
                      "
                      :disabled="!item.is_display_subscription"
                      class="text-center my-3"
                      text
                      small
                      color="primary"
                      @click="getVerif(item.id)"
                    >
                      Resend email
                    </v-btn>
                    <v-btn
                      :disabled="!item.is_display_subscription"
                      class="text-center my-3"
                      text
                      small
                      color="primary"
                      @click="getDetail(item.id)"
                    >
                      Edit
                    </v-btn>

                    <div
                      v-if="
                        user.account._id != item.id &&
                          (user.account.roles.name == 'superadmin' ||
                            (user.account.roles.name == 'admin' && item.roles == 'user'))
                      "
                    >
                      <v-btn
                        :disabled="!item.is_display_subscription"
                        class="text-center my-3"
                        text
                        small
                        color="primary"
                        @click="
                          dialog_delete.show = true;
                          dialog_delete.id = item.id;
                        "
                      >
                        Hapus
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>

          <v-snackbar v-model="isError">
            {{ showMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="primary"
                text
                v-bind="attrs"
                @click="isError = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </div>
    </v-card-text>

    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-form
          ref="createForm"
          class="px-8"
          @submit.prevent="submit"
          v-model="valid"
        >
          <v-card-title class="text-h5">
            Add New User
            <v-btn absolute right @click="dialog = false">
              <i class="ri-close-line"></i>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="my-3">
            <v-row>
              <v-col class="py-0">
                <div class="subtitle-2 mb-2 text-black mt-3">Nama Lengkap</div>
                <v-text-field
                  filled
                  v-model="fullname"
                  required
                  outlined
                  hide-details="auto"
                  :rules="[(v) => !!v || 'Nama Lengkap tidak boleh kosong']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <div class="subtitle-2 mb-2 text-black mt-3">Email</div>
                <v-text-field
                  filled
                  v-model="email"
                  required
                  outlined
                  hide-details="auto"
                  :rules="[
                    (v) => !!v || 'Email tidak boleh kosong',
                    ...emailRules,
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0">
                <div class="mb-2 subtitle-2 text-black mt-3">Kata Sandi</div>
                <v-text-field
                  filled
                  v-model="password"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show"
                  outlined
                  hide-details="auto"
                  :rules="pswRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0">
                <div class="subtitle-2 mb-2 text-black mt-3">Nomor Telepon</div>
                <v-text-field
                  filled
                  v-model="phone"
                  required
                  outlined
                  hide-details="auto"
                  :rules="[
                    (v) => !!v || 'Nomor Telepon tidak boleh kosong',
                    (v) => !isNaN(v) || 'Nomor telepon harus angka',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0">
                <div class="mb-2 subtitle-2 text-black mt-3">Peran</div>
                <v-select
                  v-model="role"
                  :items="roles"
                  required
                  :rules="[(v) => !!v || 'Peran tidak boleh kosong']"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="d-block text-center">
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              :loading="submiting"
              color="primary"
              class="text-center my-3"
            >
              Simpan Pengguna
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="resetPass.show" persistent width="500">
      <v-card>
        <v-card-title class="text-h5">
          Kata Sandi Baru
          <v-btn
            absolute
            right
            @click="
              resetPass.show = false;
              password_new = '';
              confirm_password = '';
            "
          >
            <i class="ri-close-line"></i>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text id="resetPs" class="my-3">
          <v-row>
            <v-col class="">
              <div class="subtitle-2 mb-2 text-black mt-1">Kata sandi baru</div>
              <v-text-field
                v-model="password_new"
                :rules="NewpswRules"
                required
                outlined
                hide-details="auto"
                :type="show3 ? 'text' : 'password'"
                :append-icon="show3 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                @click:append="show3 = !show3"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="">
              <div class="subtitle-2 mb-2 text-black mt-1">
                Konfirmasi kata sandi
              </div>
              <v-text-field
                v-model="confirm_password"
                :rules="cnfrmPswRules"
                outlined
                hide-details="auto"
                required
                :type="show2 ? 'text' : 'password'"
                :append-icon="show2 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                @click:append="show2 = !show2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            @click="
              resetPass.show = false;
              confirm_password = '';
              password_new = '';
            "
            class="text-center my-3"
            elevation="2"
          >
            Batalkan
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="reset()"
            class="text-center my-3"
            :disabled="confirm_password === ''"
          >
            Perbarui Kata Sandi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_update" persistent width="500">
      <v-card>
        <v-form
          ref="form"
          class="px-8"
          @submit.prevent="submit"
          v-model="valid"
        >
          <v-card-title class="text-h5">
            Update User
            <v-btn absolute right @click="dialog_update = false">
              <i class="ri-close-line"></i>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="my-3">
            <v-row>
              <v-col class="py-0">
                <div class="subtitle-2 mb-2 text-black mt-3">Nama Lengkap</div>
                <v-text-field
                  filled
                  v-model="detail.fullname"
                  required
                  outlined
                  hide-details="auto"
                  :rules="[(v) => !!v || 'Nama Lengkap tidak boleh kosong']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <div class="subtitle-2 mb-2 text-black mt-3">Email</div>
                <v-text-field
                  filled
                  v-model="detail.email"
                  required
                  outlined
                  readonly
                  hide-details="auto"
                  :rules="[(v) => !!v || 'Email tidak boleh kosong']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <div class="subtitle-2 mb-2 text-black mt-3">Nomor Telepon</div>
                <v-text-field
                  filled
                  v-model="detail.phone"
                  required
                  outlined
                  hide-details="auto"
                  :rules="[
                    (v) => !!v || 'Nomor Telepon tidak boleh kosong',
                    (v) => !isNaN(v) || 'Nomor telepon harus angka',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <div class="mb-2 subtitle-2 text-black mt-3">Peran</div>
                <v-select
                  v-model="detail.roles"
                  :items="roles"
                  required
                  readonly
                  :rules="[(v) => !!v || 'Peran tidak boleh kosong']"
                ></v-select>
              </v-col>
            </v-row>
            <!-- <p class="mb-1">Enter Email</p>
          <v-text-field
            v-model="serverParams.email"
            :rules="emailRules"
            type="email"
            filled
            required
            outlined
            hide-details="auto"
            placeholder="name@email.com"
          ></v-text-field> -->
          </v-card-text>

          <v-divider></v-divider>

          <!-- <v-card-text class="my-3">
          <p class="mb-1">Role</p>
          <v-select
            :items="ex"
            placeholder="Select Type"
            class="ma-1"
          ></v-select>
        </v-card-text> -->

          <!-- <v-divider></v-divider> -->

          <v-card-actions class="d-block text-center">
            <v-spacer></v-spacer>
            <v-btn
              @click="updateUser()"
              :loading="submiting"
              color="primary"
              class="text-center my-3"
            >
              Simpan Pengguna
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_approved" persistent max-width="290">
      <v-card>
        <!-- <v-card-title class="text-h5">
          
        </v-card-title> -->
        <v-card-text class="text-h5 pb-2 pt-4 text-center"
          >Approve User</v-card-text
        >
        <v-card-text class="text-body-1 text-center pb-2"
          >Apakah Anda yakin ingin approve user {{ nick }}?</v-card-text
        >
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialog_approved = false">
            Batalkan
          </v-btn>
          <v-btn
            color="primary"
            dark
            @click="sendApprove()"
            :loading="submiting"
          >
            Approve
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_delete.show" persistent max-width="290">
      <v-card>
        <!-- <v-card-title class="text-h5">
          
        </v-card-title> -->
        <v-card-text class="text-h5 pb-2 pt-4 text-center"
          >Hapus Pengguna</v-card-text
        >
        <v-card-text class="text-body-1 text-center pb-2"
          >Apakah Anda yakin akan menghapus akun ini?</v-card-text
        >
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialog_delete.show = false">
            Batalkan
          </v-btn>
          <v-btn color="error" dark @click="deleteUser()" :loading="submiting">
            Hapus
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_verif.show" persistent max-width="290">
      <v-card>
        <!-- <v-card-title class="text-h5">
          
        </v-card-title> -->
        <v-card-text class="text-h5 pb-2 pt-4 text-center"
          >Email Verifikasi User</v-card-text
        >
        <v-card-text class="text-body-1 text-center pb-2"
          >Apakah Anda yakin ingin mengirim kembali email
          verifikasi?</v-card-text
        >
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialog_verif.show = false">
            Batalkan
          </v-btn>
          <v-btn color="primary" dark @click="sendVerif()" :loading="submiting">
            Kirim
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

// import store from "../../store";
import { onMounted, reactive, toRefs } from "@vue/composition-api";

// import {reactive, toRefs} from "@vue/composition-api";
import { formatDate } from "@/lib/formatDate";

const headers = [
  { text: "NAMA & EMAIL", value: "fullname", class: "bg-grey" },
  { text: "TANGGAL BERGABUNG", value: "created_at", class: "bg-grey" },
  { text: "NO TELEPON", value: "phone", class: "bg-grey" },
  { text: "ROLE", value: "roles", class: "bg-grey" },
  //{ text: 'VERIFIKASI KYC', value: 'verification_code', class: "bg-grey" },
  {
    text: "VERIFIKASI EMAIL",
    value: "verified_at",
    class: "bg-grey",
    align: "center",
  },
  { text: "KATA SANDI", value: "password", class: "bg-grey" },
  { text: "", value: "action", class: "bg-grey" },
  // { text: 'STATUS KYC', value: 'status', class: "bg-grey" },
];

const ex = ["Admin", "Developer", "User", "Reviewer"];

const states = {
  search: "",
  dialog: false,
  isLoading: false,
  id: null,
  isError: false,
  showMessage: "",
  submiting: false,
  emailRules: [(v) => /.+@.+\..+/.test(v) || "E-mail tidak valid"],
  serverParams: {
    email: "",
  },
  columnFilter: {
    status: [],
  },
  listUser: [],
  roles: [],
  limit: 10,
  page: 1,
  total_data: null,
  email: "",
  password: "",
  fullname: "",
  role: "",
  phone: "",
  valid: true,
  errors: null,
  show: false,
};

const formatToDate = (value) => {
  return formatDate(value);
};

export default {
  setup() {
    const state = reactive(states);

    const filterStatus = async () => {
      state.isLoading = true;
      let { data: response } = await axios.get(
        `/auth/users/list?email=${state.search}&page=${state.page}&sort&limit=${state.limit}`
        // `https://api.pensign.id/v1/auth/users/all`
      );
      // {{base_url}}/v1/auth/users/list?email=manghaku@gmail.com&page=1&sort&limit=2
      // console.log('test',response)
      state.listUser = response.data.user;
      state.limit = response.data.pagination.total_display;
      state.page = response.data.pagination.current;
      state.total_data = response.data.pagination.total_data;
      state.isLoading = false;
    };

    onMounted(async () => {
      state.limit = null;
      state.page = null;
      filterStatus();
    });

    return {
      ...toRefs(state),
      headers,
      // listUser,
      ex,
      formatToDate,
      filterStatus,
    };
  },
  data() {
    return {
      dataCount: {},
      dialog_approved: false,
      nick: "",
      id: null,
      detail: {},
      dialog_update: false,
      dialog_delete: {
        id: null,
        show: false,
      },
      dialog_verif: {
        id: null,
        show: false,
      },
      resetPass: {
        id: null,
        show: false,
      },
      password_new: "",
      confirm_password: "",
      pswRules: [
        (v) => !!v || "Kata sandi tidak boleh kosong",
        (v) => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
          return pattern.test(v) || "Password harus memiliki setidaknya 8 karakter dan memiliki 1 huruf besar, 1 huruf kecil, 1 simbol, serta 1 angka.";
        },
      ],
      NewpswRules: [
        (v) => !!v || "Kata sandi tidak boleh kosong",
        (v) => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
          return pattern.test(v) || "Password harus memiliki setidaknya 8 karakter dan memiliki 1 huruf besar, 1 huruf kecil, 1 simbol, serta 1 angka.";
        },
      ],
      show2: false,
      show3: false,
    };
  },
  // watch: {
  //     "limit": function () {
  //       filterStatus();
  //     },
  //     "page": function () {
  //       filterStatus();
  //     },
  //   }
  mounted() {
    this.getRoles();
    this.getCounter();
  },
  computed: {
    ...mapGetters(["user"]),
    cnfrmPswRules() {
      return [
        () =>
          this.password_new === this.confirm_password ||
          "Konfirmasi kata sandi tidak sama",
        (v) => !!v || "Konfirmasi kata sandi tidak boleh kosong",
      ];
    },
  },
  methods: {
    getCounter() {
      this.axios.get("/subscription/count").then((response) => {
        let res = response.data.data;
        this.dataCount = res;
      });
    },
    getList() {
      this.axios
        .get("/auth/users/list?email=&page=null&sort&limit=null")
        .then((response) => {
          let res = response.data.data.user;
          this.listUser = res;
        });
    },
    getDetail(id) {
      this.axios.get(`/auth/dashboard/user/${id}`).then((response) => {
        let res = response.data.data;
        this.detail = res;
        this.dialog_update = true;
      });
    },
    getVerif(id) {
      this.dialog_verif.show = true;
      this.dialog_verif.id = id;
    },
    getPass(id) {
      this.resetPass.show = true;
      this.resetPass.id = id;
    },
    sendVerif() {
      this.submiting = true;
      // const param={
      //   id:this.dialog_verif.id,
      // }
      this.axios
        .post(`/auth/dashboard/resend/${this.dialog_verif.id}/activation`)
        .then((response) => {
          // console.log(response)
          this.submiting = false;
          this.showMessage = response.data.msg;
          this.isError = true;
          this.dialog_verif.show = false;
          this.getList();
        })
        .catch((error) => {
          // console.log(error.response)
          this.submiting = false;
          this.showMessage = error.response.data.msg;
          this.isError = true;
          this.dialog_verif.show = false;
        });
    },
    updateUser() {
      this.submiting = true;

      const param = {
        id: this.detail.id,
        phone: this.detail.phone,
        fullname: this.detail.fullname,
        email: this.detail.email,
        role: this.detail.roles,
      };
      this.axios.post("/auth/dashboard/edit_user", param).then((response) => {
        this.submiting = false;
        if (response.data.code == 200) {
          this.showMessage = response.data.msg;
          this.isError = true;
          this.dialog_update = false;
          this.getList();

          const user = this.$store.getters.user;
          const user_id = user.account._id;

          if (this.detail.id == user_id) {
            user.account.profile.fullname = this.detail.fullname;
            this.$store.commit("SET_USER", user);
          }
        } else {
          this.showMessage = response.data.msg;
          this.isError = true;
        }
      });
    },
    deleteUser() {
      this.submiting = true;
      const param = {
        id: this.dialog_delete.id,
      };
      this.axios.post("/auth/dashboard/delete_user", param).then((response) => {
        console.log(response);
        this.submiting = false;
        if (response.data.code == 200) {
          this.showMessage = response.data.msg;
          this.isError = true;
          this.dialog_delete.show = false;
          this.getList();
          this.$root.$emit("setDialog", true);
        } else {
          this.showMessage = response.data.msg;
          this.isError = true;
        }
      });
    },
    getRoles() {
      this.axios
        .get("/auth/roles/all")
        .then((res) => res.data.data)
        .then((res) => {
          if (this.user.account.roles.name == "superadmin") this.roles = res;
          else this.roles = ["user"];
          console.log(this.roles);
        });
    },
    sendApprove() {
      this.submiting = true;
      let data = {
        id: this.id,
      };

      this.axios
        .post(`auth/dashboard/approval_user`, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.submiting = false;
            this.dialog_approved = false;
            this.showMessage = res.data.msg;
            this.isError = true;
            this.getList();
            setTimeout(() => {
              this.isError = false;
              this.showMessage = null;
            }, 3000);
          } else {
            this.submiting = false;
            this.isError = true;
            this.dialog_approved = false;
            this.showMessage = res.data.msg;
            setTimeout(() => {
              this.isError = false;
              this.showMessage = null;
            }, 3000);
          }
        })
        .catch(() => {
          this.submiting = false;
          this.isError = true;
          this.showMessage = "Failed approve member!";
          setTimeout(() => {
            this.isError = false;
            this.showMessage = null;
          }, 3000);
        });
    },
    submit() {
      setTimeout(() => {
        if (this.$refs.createForm.validate()) {
          this.submiting = true;
          let data = {
            fullname: this.fullname,
            email: this.email,
            password: this.password,
            role: this.role,
            phone: this.phone,
          };

          this.axios
            .post(`auth/dashboard/add_user`, data, {
              headers: { "Content-Type": "application/json" },
            })
            .then(() => {
              this.submiting = false;
              this.dialog = false;
              this.showMessage = "Success add member!";
              this.isError = true;
              this.getCounter();
              setTimeout(() => {
                this.isError = false;
                this.showMessage = null;
              }, 3000);
              this.getList();
              this.$root.$emit("setDialog", true);
              this.$refs.createForm.reset();
            })
            .catch((error) => {
              this.submiting = false;
              this.isError = true;
              this.showMessage = error.response.data.msg;
              setTimeout(() => {
                this.isError = false;
                this.showMessage = null;
              }, 3000);
            });
        }
      }, 50);
    },
    getReset(id) {
      // console.log(id);
      this.id = id;
      this.resetPass = true;
    },
    reset() {
      let data = {
        // setTimeout(() => {
        //   if (this.$refs.form.validate()) {
        //     this.submiting = true;
        //     let data = {
        //       fullname: this.fullname,
        //       email: this.email,
        //       password: this.password,
        //       role: this.role,
        //       phone: this.phone,
        //     };

        //     this.axios
        //       .post(`auth/dashboard/add_user`, data, {
        //         headers: { 'Content-Type': 'application/json' },
        //       })
        //       .then(() => {
        //         this.submiting = false;
        //         this.dialog = false;
        //         this.showMessage = 'Success add member!';
        //         this.isError = true;
        //         this.getCounter();
        //         setTimeout(() => {
        //           this.isError = false;
        //           this.showMessage = null;
        //         }, 3000);
        //         this.getList();
        //         this.$root.$emit('setDialog', true);
        //         this.$refs.form.reset();
        //       })
        //       .catch((error) => {
        //         this.submiting = false;
        //         this.isError = true;
        //         this.showMessage = error.response.data.msg;
        //         setTimeout(() => {
        //           this.isError = false;
        //           this.showMessage = null;
        //         }, 3000);
        //       })
        //       .finnaly(() => {
        //         this.$refs.createForm.reset();
        //       });
        //   }
        // }, 50);
        user_id: this.resetPass.id,
        password: this.password_new,
        password_confirm: this.confirm_password,
      };
      this.axios
        .post(`auth/dashboard/manage_user/edit_password`, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.submiting = false;
            this.isError = true;
            this.showMessage = res.data.msg;
            this.resetPass.show = false;
            this.password_new = "";
            this.confirm_password = "";
            setTimeout(() => {
              this.isError = false;
              this.showMessage = null;
            }, 3000);
          } else {
            this.submiting = false;
            this.isError = true;
            this.showMessage = res.data.msg;
            this.password_new = "";
            this.confirm_password = "";
            setTimeout(() => {
              this.isError = false;
              this.showMessage = null;
            }, 3000);
          }
        })
        .catch(() => {
          this.submiting = false;
          // this.$refs.form.reset();
          this.isError = true;
          this.showMessage = "Failed reset password!";
          setTimeout(() => {
            this.isError = false;
            this.showMessage = null;
          }, 3000);
        });
    },
  },
};
</script>

<style>
.bg-grey {
  background-color: #f9f9f9;
}
</style>
