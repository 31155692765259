<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="text-black pt-0 pb-0 px-0 fs-20 font-weight-bold">Konfirmasi Pembayaran</v-card-title>

    <v-row>
      <v-col cols="8">
        <v-card-text class="px-0">
          <v-form
            ref="form"
            @submit.prevent="submit"
            v-model="valid"
          >
            <v-card elevation="1">
              <v-row class="mt-3 my-0 mx-0 px-3 py-4">
                <v-col cols="12" class="pt-0">
                  <h3 class="text-black mb-2">Pensign Bank Information Detail</h3>
                  <p class="text-black font-weight-thin fs-14 mb-0">
                    Make a bank transfer to this pensign account number below before
                    <span class="font-weight-bold">{{ getDate(detailListDeposit.due_date) }}</span>
                  </p>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row class="my-0 mx-0 px-3 py-4">
                <v-col cols="6">
                  <div class="d-flex align-center">
                    <!-- <img :src="detailListDeposit.gambar_bank" style="width: 150px;" > -->
                    <img :src="detailListDeposit.gambar_bank" style="width: 150px;" >
                    <div class="ml-2 text-black">
                      <!-- <p class="mb-0" style="font-weight:500;">{{detailListDeposit.bank_name}}</p> -->
                      <p class="mb-0 text-uppercase" style="font-weight:500;">{{detailListDeposit.account_name}}</p>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" class="d-flex align-center justify-content-end">
                  <div class="d-flex">
                    <h2 class="text-black mr-2" id="textToCopy" ref="textToCopy">{{ detailListDeposit.rekening }}</h2>
                    <v-icon @click="handleCopy(detailListDeposit.rekening)">ri-file-copy-fill</v-icon>
                  </div>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <div class="px-3 py-3 bg-grey">

                <v-row class="pb-4 px-3">
                  <v-col cols="6">
                    <p class="font-weight-thin text-black mb-0">Amount to Add Fund</p>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center justify-content-end">
                    <div class="d-flex">
                      <h3 class="text-black mr-2 font-weight-thin">Rp. {{ formatPrice(detailListDeposit.total_amount) }}</h3>
                    </div>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row v-if="detailListDeposit.unique_code != null" class="py-4 px-3">
                  <v-col cols="6">
                    <p class="font-weight-thin text-black mb-0">Kode Unik</p>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center justify-content-end">
                    <div class="d-flex">
                      <h3 class="text-black mr-2 font-weight-thin">{{detailListDeposit.unique_code}}</h3>
                    </div>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row class="py-6 px-3">
                  <v-col cols="6">
                    <p class="font-weight-bold text-black mb-0">TOTAL TRANSFER</p>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center justify-content-end">
                    <div class="d-flex">
                      <h3 class="text-black mr-2">Rp. {{ formatPrice(detailListDeposit.total_invoice) }}</h3>
                      <v-icon @click="handleCopy(detailListDeposit.total_invoice)">ri-file-copy-fill</v-icon>
                    </div>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row class="py-4 px-3">
                  <v-col cols="12">
                    <p class="font-weight-thin text-black mb-0">
                      Ensure that the transfer amount is exactly the same as the amount shown above. The ticket number is used to speed up the verification process. This ticket number will be add to your balance
                    </p>
                  </v-col>
                </v-row>

              </div>

              <v-divider></v-divider>

              <v-row class="my-0 mx-0 px-3 py-4">
                <v-col cols="12">
                  <div>
                    <h3 class="font-weight-bold mb-2 text-black">Confirm Transfer</h3>
                    <p class="mb-0 text-black" style="font-weight: 600;">
                      <v-icon small class="mr-2" color="success">ri-checkbox-circle-line</v-icon>
                      I confirm i have initiated a transfer to Pensign Account via Bank Transfer
                    </p>
                  </div>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-card-actions>
                <div class="row">
                  <div class="col-sm-12 text-right px-0">
                    <v-btn color="primary" outlined class="mr-2 px-5" :to="{ name: 'inventory'}">
                      Batal
                    </v-btn>
                    <v-btn color="primary" dark class="px-3" @click="confirmTransfer()" :loading="submiting" >
                      Konfirmasi Transfer
                    </v-btn>
                  </div>
                </div>
              </v-card-actions>

            </v-card>
          </v-form>


          <v-snackbar
              v-model="isError"
          >
            {{ showMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="isError = false"
              >
              Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-card-text>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      width="700"
    >
      <v-card>
        <v-icon class="px-4 py-4" small color="#CFD8DC" @click="dialog = false">ri-close-line</v-icon>
        <v-row class="my-0 mx-0 px-5 py-5">
          <v-col cols="12">
            <div class="d-flex align-center justify-content-center" style="gap: 20px;">
              <img src="@/assets/images/biru.png" >
              <img src="@/assets/images/loading.png">
              <img src="@/assets/images/orange.png" >
            </div>
            <div class="mt-5 text-center">
              <p class="font-weight-bold mb-2 text-black">Kami sedang mengecek Transferanmu</p>
              <p class="font-weight-thin mb-0 text-black">It may take a while to complete the transaction</p>
            </div>
          </v-col>
        </v-row>
      </v-card>
  </v-dialog>

  </v-card>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import { formatPrice } from "@/lib/formatDate";

export default {
  data: () => ({
    valid: true,
    dialog:false,
    isError: false,
    submiting: false,
    showMessage: '',
    listBank: [],
    detailListDeposit: {
      bank_name: "",
      account_name: "",
      rekening: "",
      gambar_bank: "",
      total_amount: "",
      invoice_total_amount: "",
      due_date: "",
      unique_code: "",
    },
    serverParams: {
      invoice_code: 0
    }
  }),
  mounted() {
    this.getDepositDetail();
  },
  computed: {
    idParam: {
      get() {
        return this.$route.params.id;
      }
    }
  },
  methods: {
    getDepositDetail() {
      return new Promise((resolve, reject) => {
        axios
        .get(`econtract/inventory/client/order?order_id=${this.idParam}` )
        .then(response => {
          // this.detailListDeposit = response.data.data.detail;
          this.detailListDeposit.bank_name = response.data.data.payment_channel_detail.bank_name;
          this.detailListDeposit.account_name = response.data.data.payment_channel_detail.account_name;
          this.detailListDeposit.rekening = response.data.data.payment_channel_detail.account_number;
          this.detailListDeposit.gambar_bank = response.data.data.payment_channel_detail.bank_image;
          this.detailListDeposit.total_amount = response.data.data.total_price;
          this.detailListDeposit.total_invoice = response.data.data.total_invoice;
          this.detailListDeposit.due_date = response.data.data.order_datetime;
          this.detailListDeposit.unique_code = response.data.data.unique_code;
          this.serverParams.invoice_code = response.data.data.detail.code;
          resolve(response);
        })
        .catch(error => {
            reject(error);
        });
      });
    },
    getDate(a) {
      return moment(a).locale('ID').format("ll hh:mm");
    },

    confirmTransfer() {      
      this.dialog = true;
      this.submiting = true;      
      const data={
        order_id:this.$route.params.id
      }

      this.axios.post(`econtract/inventory/client/confirm_payment`, data)
      // await this.$store.dispatch("requestDepositConfirmation", this.serverParams)
      .then((response) => {
          this.isError = true;
          this.showMessage = response.data.msg;
          this.submiting = false;
        setTimeout( () => {
          this.dialog = false;
          // this.$refs.form.reset();
          this.$router.push({name: 'inventory-products'})
        }, 5000);
      })
      .catch((error) => {
        this.dialog = false;        
        this.submiting = false;
        this.isError = true;
        this.showMessage = error.response.data.msg;
      });
    },

    handleCopy(value) {
      try {
        navigator.clipboard.writeText(value);
        this.isError = true;
        this.showMessage = "Copied to clipboard";
      } catch (error) {
        this.isError = true;
        this.showMessage = "Copied failed";
      }
    },

    formatPrice(value) {
      return formatPrice(value);
    },

    validateField() {
      this.$refs.form.validate();
    }
  },

  // setup() {
  //   const handleCopy = (value) => {
  //     try {
  //       navigator.clipboard.writeText(value);
  //       this.isError = true;
  //       this.showMessage = "Copied to clipboard";
  //     } catch (error) {
  //       this.isError = true;
  //       this.showMessage = "Copied failed";
  //     }
  //   };

  //   return {
  //     handleCopy
  //   }
  // }
};
</script>

<style scoped lang="scss">
  .justify-content-end {
    justify-content: end;
  }
  .justify-content-center {
    justify-content: center;
  }
  .bg-grey {
    background-color: #F9F9F9;
  }
</style>