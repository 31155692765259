
<template>
  <div>
    <v-card v-if="data" rounded="lg" class="transparent">
      <v-breadcrumbs :items="items" class="px-0">
        <template v-slot:divider>
          <v-icon>ri-arrow-right-s-line</v-icon>
        </template>
      </v-breadcrumbs>
      <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold"
        >Certificate</v-card-title
      >
      <v-card-text class="px-0">
        <div class="content">
          <div class="table my-3">
            <v-card class="mt-5">
              <v-card-title class="font-weight-regular">
                <v-row align="center">
                  <v-col lg="6">
                    <p class="fs-16 text-black font-weight-medium">
                      {{ data && data.title }}
                    </p>
                  </v-col>

                  <v-col
                    id="search"
                    lg="6"
                    class="justify-end align-end text-right"
                  >
                  </v-col>
                </v-row>
                <div class="col-md-12 px-0 py-4">
                  <v-divider></v-divider>
                </div>
                <div class="row">
                  <div class="col-md-4 px-1">
                    <v-card rounded="lg" class="transparent px-0">
                      <v-card-text class="py-0">
                        <v-list three-line>
                          <v-list-item class="pl-0">
                            <img
                              loading="lazy"
                              :src="
                                (data && data.preview) ||
                                  `https://via.placeholder.com/728x400.png?text=${data &&
                                    data.title}`
                              "
                              class="img-table mr-1 ms-0"
                            />
                            <v-list-item-content>
                              <p class="text-semu fs-12 mb-2 wb">
                                CERTIFICATE TITLE
                              </p>
                              <p class="fs-14 text-black font-weight-medium wb">
                                {{ data && data.title }}
                              </p>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </div>
                  <v-divider vertical></v-divider>
                  <div class="col-md-2 px-1">
                    <v-card rounded="lg" class="transparent px-0">
                      <v-card-text class="py-5">
                        <p class="text-semu fs-12 mb-2 wb">
                         ISSUANCE DATE
                        </p>
                        <p class="fs-14 text-black font-weight-medium wb">
                          {{
                            data &&
                              data.created_at | moment("dddd, D MMMM YYYY")
                          }}
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <v-divider vertical></v-divider>
                  <div class="col-md-2 px-1">
                    <v-card rounded="lg" class="transparent px-0">
                      <v-card-text class="py-5">
                        <p class="text-semu fs-12 mb-2 wb">RECIPIENTS</p>
                        <p class="fs-14 text-black font-weight-medium wb">
                          {{ getTotalRecipient }} Recipients
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <v-divider vertical></v-divider>
                  <div class="col-md-2 px-1">
                    <v-card rounded="lg" class="transparent px-0">
                      <v-card-text class="py-5">
                        <p class="text-semu fs-12 mb-2 wb">LAST UPDATED</p>
                        <p class="fs-14 text-black font-weight-medium wb">
                          {{
                            data &&
                              data.updated_at | moment("dddd, D MMMM YYYY")
                          }}
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <v-divider vertical></v-divider>
                  <div class="col-md-2 px-1">
                    <v-card rounded="lg" class="transparent px-0">
                      <v-card-text class="py-5">
                        <v-btn
                          @click="preview = true"
                          block
                          outlined
                          color="primary"
                          class="mb-2"
                          >Preview</v-btn
                        >
                        <v-dialog v-model="preview" max-width="60%">
                          <v-card class="d-flex justify-center items-center">
                            <img
                              style="width: 100%; height: 100%; object-fit: cover;"
                              :src="data.preview"
                              alt="image"
                            />
                          </v-card>
                        </v-dialog>
                        <v-btn
                          :to="{
                            name: 'edit-template',
                          }"
                          block
                          outlined
                          color="primary"
                          class=""
                          >Edit</v-btn
                        >
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
                <div class="col-md-12 px-0 py-4">
                  <v-divider></v-divider>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="text-black fs-16 font-weight-medium mb-0">
                      Add Recipients
                    </p>
                  </div>
                  <div class="col-md-6">
                    <v-text-field
                      outlined
                      append-icon="$search"
                      class="mt-1 ms-2 mx-width-85"
                      placeholder="Search Recipients"
                      solo
                      v-model="search"
                      flat
                    ></v-text-field>
                  </div>
                  <div class="col-md-6 text-end align-end">
                    <v-btn
                      color="grey darken-4"
                      class="white--text ma-1"
                      @click="add = true"
                    >
                      <v-icon class="mr-2">ri-user-add-fill</v-icon> Add
                      Recipient Manually
                    </v-btn>
                    <v-btn
                      @click="modalImport = true"
                      color="grey darken-4"
                      class="white--text ma-1"
                    >
                      <v-icon class="mr-2">ri-file-excel-2-fill</v-icon>
                      Export CSV or Excel
                    </v-btn>
                  </div>
                </div>
              </v-card-title>
              <div v-if="!recipient" class="col-md-6 mx-auto my-2">
                <img src="@/assets/images/certif-empty.svg" />
                <p class="font-weight-bold text-black mb-2">
                  Empty Recipient
                </p>
                <p class="text-semu">

Create a new certificate or try to narrow the search if you
                  can't find the certificate you're looking for
                </p>
              </div>
              <v-data-table
                v-else
                :headers="headers"
                :items="recipient"
                :search="search"
              >
                <template v-slot:[`item.status`]="{item}">
                  <v-chip
                    v-if="item.status === 'Issued'"
                    color="success"
                    outlined
                    small
                  >
                    {{ item.status }}
                  </v-chip>
                  <v-chip
                    v-else
                    :color="item.status == 'Prececced' ? '#F0AB0B' : '#2665DE'"
                    outlined
                    small
                  >
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:[`item.action`]="{item}">
                  <v-icon small class="mr-2" @click="openEdit(item)">
                    ri-pencil-fill
                  </v-icon>
                  <v-icon small @click="deleteRecipient(item)">
                    ri-delete-bin-6-fill
                  </v-icon>
                </template>
              </v-data-table>
              <v-divider></v-divider>
              <v-card-actions class="py-5">
                <v-spacer></v-spacer>
                <v-btn color="primary" class="text-center px-3" outlined>
                  Back
                </v-btn>
                <v-btn
                  color="primary"
                  @click="exp = true"
                  class="text-center px-3"
                  :title="
                    !expdate
                      ? 'Pilih Tanggal Kadaluarsa Terlebih Dahulu'
                      : 'Terbitkan Sertifikat'
                  "
                >
                  Next
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="add" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 text-black">
          Add Recipient
        </v-card-title>

        <form @submit.prevent="addRecipient()">
          <v-card-text class="my-3">
            <div v-if="design && design.schema == 'Degree'">
              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Name
                  </div>
                  <v-text-field
                    v-model="temp.name"
                    filled
                    outlined
                    hide-details="auto"
                    :placeholder="'Enter Name'"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Email
                  </div>
                  <v-text-field
                    v-model="temp.email"
                    filled
                    outlined
                    :name="recipient.form"
                    hide-details="auto"
                    :placeholder="'Enter Email'"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Phone Number
                  </div>
                  <v-text-field
                    v-model="temp.phone"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Phone"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Birth Place
                  </div>
                  <v-text-field
                    v-model="temp.birthPlace"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Birth Place"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Activity Date
                  </div>
                  <v-menu
                    v-model="activity"
                    :close-on-content-click="false"
                    max-width="60%"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        append-icon="ri-calendar-line"
                        v-model="temp.activityDate"
                        filled
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        placeholder="Enter Activity Date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="activity = false"
                      v-model="temp.activityDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Credential Number
                  </div>
                  <v-text-field
                    v-model="temp.credentialNumber"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Credential Number"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Graduation Date
                  </div>
                  <v-menu
                    v-model="issuance"
                    :close-on-content-click="false"
                    max-width="60%"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        append-icon="ri-calendar-line"
                        v-model="temp.graduationDate"
                        filled
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        placeholder="Enter Graduation Date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="issuance = false"
                      v-model="temp.graduationDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Birth Date
                  </div>
                  <v-menu
                    v-model="birth"
                    :close-on-content-click="false"
                    max-width="60%"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        append-icon="ri-calendar-line"
                        v-model="temp.birthDate"
                        filled
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        placeholder="Enter Birth Date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="issuance = false"
                      v-model="temp.birthDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Identity Number
                  </div>
                  <v-text-field
                    v-model="temp.identityNumber"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Identity Number"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Issuance Date
                  </div>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    max-width="60%"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        append-icon="ri-calendar-line"
                        v-model="temp.issuanceDate"
                        filled
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        placeholder="Enter Phone"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="menu = false"
                      v-model="temp.issuanceDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Issuance Place
                  </div>
                  <v-text-field
                    v-model="temp.issuancePlace"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Issuance Place"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Predicate
                  </div>
                  <v-text-field
                    v-model="temp.predicate"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Predicate"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Student Number
                  </div>
                  <v-text-field
                    v-model="temp.studentNumber"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Student Number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <div v-if="design && design.schema == 'Certificate'">
              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Name
                  </div>
                  <v-text-field
                    v-model="temp.name"
                    filled
                    outlined
                    hide-details="auto"
                    :placeholder="'Enter Name'"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Email
                  </div>
                  <v-text-field
                    v-model="temp.email"
                    filled
                    outlined
                    :name="recipient.form"
                    hide-details="auto"
                    :placeholder="'Enter Email'"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Phone Number
                  </div>
                  <v-text-field
                    v-model="temp.phone"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Phone"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Birth Place
                  </div>
                  <v-text-field
                    v-model="temp.birthPlace"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Birth Place"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Activity Date
                  </div>
                  <v-menu
                    v-model="activity"
                    :close-on-content-click="false"
                    max-width="60%"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        append-icon="ri-calendar-line"
                        v-model="temp.activityDate"
                        filled
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        placeholder="Enter Activity Date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="activity = false"
                      v-model="temp.activityDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Credential Number
                  </div>
                  <v-text-field
                    v-model="temp.credentialNumber"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Credential Number"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Birth Date
                  </div>
                  <v-menu
                    v-model="birth"
                    :close-on-content-click="false"
                    max-width="60%"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        append-icon="ri-calendar-line"
                        v-model="temp.birthDate"
                        filled
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        placeholder="Enter Birth Date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="issuance = false"
                      v-model="temp.birthDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Issuance Date
                  </div>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    max-width="60%"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        append-icon="ri-calendar-line"
                        v-model="temp.issuanceDate"
                        filled
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        placeholder="Enter Phone"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="menu = false"
                      v-model="temp.issuanceDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Issuance Place
                  </div>
                  <v-text-field
                    v-model="temp.issuancePlace"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Issuance Place"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Predicate
                  </div>
                  <v-text-field
                    v-model="temp.predicate"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Predicate"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="py-5">
            <v-spacer></v-spacer>
            <v-btn
              type="button"
              color="primary"
              @click="add = false"
              class="text-center px-3"
              outlined
            >
              Cancel
            </v-btn>
            <v-btn color="primary" type="submit" class="text-center px-3">
              {{ !isFormLoading ? "Add Recipient" : "Loading" }}
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="edit" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 text-black">
          Edit Recipient
        </v-card-title>

        <form @submit.prevent="editRecipient()">
          <v-card-text class="my-3">
            <div v-if="design && design.schema == 'Degree'">
              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Name
                  </div>
                  <v-text-field
                    v-model="temp.name"
                    filled
                    outlined
                    hide-details="auto"
                    :placeholder="'Enter Name'"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Email
                  </div>
                  <v-text-field
                    v-model="temp.email"
                    filled
                    outlined
                    :name="recipient.form"
                    hide-details="auto"
                    :placeholder="'Enter Email'"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Phone Number
                  </div>
                  <v-text-field
                    v-model="temp.phone"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Phone"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Birth Place
                  </div>
                  <v-text-field
                    v-model="temp.birthPlace"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Birth Place"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Activity Date
                  </div>
                  <v-menu
                    v-model="activityDate"
                    :close-on-content-click="false"
                    max-width="60%"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        append-icon="ri-calendar-line"
                        v-model="temp.activityDate"
                        filled
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        placeholder="Enter Activity Date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="activity = false"
                      v-model="temp.activityDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Credential Number
                  </div>
                  <v-text-field
                    v-model="temp.credentialNumber"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Credential Number"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Graduation Date
                  </div>
                  <v-menu
                    v-model="graduation"
                    :close-on-content-click="false"
                    max-width="60%"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        append-icon="ri-calendar-line"
                        v-model="temp.graduationDate"
                        filled
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        placeholder="Enter Graduation Date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="issuance = false"
                      v-model="temp.graduationDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Birth Date
                  </div>
                  <v-menu
                    v-model="birth"
                    :close-on-content-click="false"
                    max-width="60%"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        append-icon="ri-calendar-line"
                        v-model="temp.birthDate"
                        filled
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        placeholder="Enter Birth Date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="birth = false"
                      v-model="temp.birthDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Identity Number
                  </div>
                  <v-text-field
                    v-model="temp.identityNumber"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Identity Number"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Issuance Date
                  </div>
                  <v-menu
                    v-model="issuanceDateEdit"
                    :close-on-content-click="false"
                    max-width="60%"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        append-icon="ri-calendar-line"
                        v-model="temp.issuanceDate"
                        filled
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        placeholder="Enter Phone"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="issuanceDateEdit = false"
                      v-model="temp.issuanceDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Issuance Place
                  </div>
                  <v-text-field
                    v-model="temp.issuancePlace"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Issuance Place"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Predicate
                  </div>
                  <v-text-field
                    v-model="temp.predicate"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Predicate"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Student Number
                  </div>
                  <v-text-field
                    v-model="temp.studentNumber"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Student Number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <div v-if="design && design.schema == 'Certificate'">
              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Name
                  </div>
                  <v-text-field
                    v-model="temp.name"
                    filled
                    outlined
                    hide-details="auto"
                    :placeholder="'Enter Name'"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Email
                  </div>
                  <v-text-field
                    v-model="temp.email"
                    filled
                    outlined
                    :name="recipient.form"
                    hide-details="auto"
                    :placeholder="'Enter Email'"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Phone Number
                  </div>
                  <v-text-field
                    v-model="temp.phone"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Phone"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Birth Place
                  </div>
                  <v-text-field
                    v-model="temp.birthPlace"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Birth Place"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Activity Date
                  </div>
                  <v-menu
                    v-model="activity"
                    :close-on-content-click="false"
                    max-width="60%"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        append-icon="ri-calendar-line"
                        v-model="temp.activityDate"
                        filled
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        placeholder="Enter Activity Date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="activity = false"
                      v-model="temp.activityDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Credential Number
                  </div>
                  <v-text-field
                    v-model="temp.credentialNumber"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Credential Number"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Birth Date
                  </div>
                  <v-menu
                    v-model="birth"
                    :close-on-content-click="false"
                    max-width="60%"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        append-icon="ri-calendar-line"
                        v-model="temp.birthDate"
                        filled
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        placeholder="Enter Birth Date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="issuance = false"
                      v-model="temp.birthDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Issuance Date
                  </div>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    max-width="60%"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        append-icon="ri-calendar-line"
                        v-model="temp.issuanceDate"
                        filled
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        placeholder="Enter Phone"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="menu = false"
                      v-model="temp.issuanceDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Issuance Place
                  </div>
                  <v-text-field
                    v-model="temp.issuancePlace"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Issuance Place"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0">
                  <div class="subtitle-2 mb-2 text-black mt-3">
                    Predicate
                  </div>
                  <v-text-field
                    v-model="temp.predicate"
                    filled
                    outlined
                    hide-details="auto"
                    placeholder="Enter Predicate"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="py-5">
            <v-spacer></v-spacer>
            <v-btn
              type="button"
              color="primary"
              @click="closeEdit()"
              class="text-center px-3"
              outlined
            >
              Cancel
            </v-btn>
            <v-btn color="primary" type="submit" class="text-center px-3">
              {{ !isFormLoading ? "Edit Recipient" : "Loading" }}
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="exp" width="600" persistent>
      <v-card>
        <v-card-title class="text-h5 text-black">
          Publish Certificate
        </v-card-title>

        <v-card-text class="my-3">
          <v-alert outlined type="warning" class="fs-12">

Before your certificate is issued, please enter the date
            expiration date for the certificate to be issued. Leave it blank
            if your certificate has no expiration date!
          </v-alert>
          <v-row>
            <v-col class="py-0">
              <div class="subtitle-2 mb-2 mt-3">Expiried Date</div>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                max-width="60%"
              >
                <template v-slot:activator="{on, attrs}">
                  <v-text-field
                    clearable
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    v-model="expdate"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="expdate"
                  @change="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="exp = false"
            class="text-center px-3"
            outlined
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="terbit = true"
            class="text-center px-3"
          >
            Add Expiried Date
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="terbit" width="600" persistent>
      <v-card>
        <v-card-title class="text-h5 text-black">
          Publish Certificate
        </v-card-title>

        <v-card-text class="my-3">
          <v-alert outlined type="warning" class="fs-12">

You will issue the certificate to the list of recipients. you still
            can issue additional certificates after this process.
          </v-alert>
          <v-row>
            <v-col class="py-0">
              <div class="fs-16 font-weight-medium mb-2 mt-3">
Are you sure you want to issue a certificate for
                {{ getTotalRecipient }} recipient?
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-5">
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            @click="terbit = false"
            class="text-center px-3"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="text-center px-3"
            @click="publishCertificate()"
          >
            Publish Certificate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalImport" persistent max-width="60%">
      <v-card>
        <form @submit.prevent="submitExcel" enctype="multipart/form-data">
          <v-card-title class="text-h5"> Upload File Excel </v-card-title>
          <div class="pa-5">
            <div class="mb-3">
              <v-btn
                color="primary"
                text
                class="text-center px-3"
                @click="downloadExample"
              >
                Download Sample File
              </v-btn>
            </div>
            <label for="import" @dragover.prevent @drop.prevent>
              <div @drop="dragDrop" class="dragDrop">
                <div class="d-flex justify-center  flex-column align-center">
                  <p>Drag or drop file</p>
                  <input
                    @change="handleFileUpload"
                    class="d-none"
                    type="file"
                    name="import"
                    id="import"
                  />
                  <div>
                    <p>{{ fileImport.name }}</p>
                  </div>
                </div>
              </div>
            </label>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined text @click="modalImport = false">
              Batal
            </v-btn>
            <v-btn type="submit" color="primary" class="text-center px-3">
              {{ isFormLoading ? "Sedang Memuat" : "Upload File" }}
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="hasError">
      {{ message }}
      <template v-slot:action="{attrs}">
        <v-btn color="red" text v-bind="attrs" @click="hasError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="hasFormError">
      {{ message }}
      <template v-slot:action="{attrs}">
        <v-btn color="red" text v-bind="attrs" @click="hasFormError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {computed, onMounted, reactive, toRefs} from "@vue/composition-api";
import useFetch from "../../../hooks/useFetch";
import router from "../../../router";
import api from "../../../store/api";
export default {
  created() {
    document.title = "Create Receive-Pensign Admin";
  },
  methods: {
    openEndDatePicker: function() {
      this.$refs.endDatePicker.showCalendar();
    },
  },
  setup(props, ctx) {
    const {fetchData} = useFetch();
    const route = ctx.root._route;
    // create fetch data
    const state = reactive({
      items: [
        {
          text: "E-Certificate",
          disabled: false,
          href: "#",
        },
        {
          text: "Certificate",
          disabled: false,
          href: "/template",
        },
        {
          text: "Detail",
          disabled: true,
          href: "#",
        },
      ],
      headers: [
        {
          text: "RECIPIENT",
          align: "start",
          sortable: false,
          value: "name",
        },
        {text: "EMAIL", value: "email"},
        {text: "PHONE NUMBER", value: "mobilephone"},
        {text: "EXPIRATION DATE", value: "expirationDate"},
        {text: "STATUS", value: "status"},
        {text: "", value: "action"},
      ],
      data: null,
      preview: false,
      design: null,
      recipient: [],
      formRecipient: [],
      isLoading: true,
      message: "",
      hasError: false,
      datepick: false,
      add: false,
      exp: false,
      publish: false,
      terbit: false,
      menu: false,
      date: "",
      fileImport: "",
      isFormLoading: false,
      hasFormError: false,
      search: "",
      expdate: "",
      edit: false,
      temp: {},
      modalImport: false,
      form: {
        addRecipient: {
          template_id: "",
          recipients: [],
        },
        editRecipient: {
          template_id: "",
          recipients: {},
        },
      },
    });

    const openEdit = (data) => {
      state.edit = true;
      state.form.editRecipient.template_id = route.params.id;
      state.temp = data;
      if (state.form.editRecipient.recipients <= 1) {
        state.form.editRecipient.recipients.push(data);
      }
    };

    const closeEdit = () => {
      state.temp = {};
      state.edit = false;
    };

    const editRecipient = async () => {
      state.form.editRecipient.recipients = state.temp;
      state.isFormLoading = true;
      let res = await fetchData({
        url: api.recipient_edit,
        method: "post",
        data: state.form.editRecipient,
      });
      if (!res.hasError) {
        state.temp = {};
        state.edit = false;
        getRecipientList();
        state.isFormLoading = false;
      } else {
        state.hasError = true;
        state.message = res.response.data.message;
      }
      state.isFormLoading = false;
    };

    const getTotalRecipient = computed(() => {
      return state.recipient.length;
    });

    const publishCertificate = async () => {
      let data = await fetchData({
        url: api.publish_certificate,
        method: "post",
        data: PublishData.value,
      });
      if (!data.hasError) {
        state.message = "Sertifikat Telah Diterbitkan";
        router.push({
          name: "list-certificate",
        });
      } else {
        state.hasError = true;
        state.message = data.response.data.message;
      }
      state.terbit = false;
      state.exp = false;
    };

    const PublishData = computed(() => {
      return {
        template_id: route.params.id,
        expiration_date: state.expdate,
        issuance_date: state.data.created_at,
      };
    });

    const deleteRecipient = async (data) => {
      let res = await fetchData({
        url: api.recipient_delete,
        method: "post",
        data: {
          template_id: data.template_id,
          recipient_id: {
            recipient_ids: [data.id],
          },
        },
      });
      if (!res.hasError) {
        getRecipientList();
        getDataById();
      } else {
        state.message = res.response.data.message;
        state.hasError = true;
      }
    };

    const getDataById = async () => {
      state.isLoading = true;
      let data = await fetchData({
        url: api.template_detail + route.params.id,
        method: "get",
      });
      if (!data.hasError) {
        state.design = data.response.data.design;
        state.data = data.response.data.template;
        state.items[2].text = data.response.data.template.title;
        state.formRecipient = data.response.data.formRecipient;
        state.form.addRecipient.template_id = data.response.data.template.id;
      } else {
        state.hasError = true;
        state.message = data.message;
      }
      state.isLoading = false;
    };

    const getRecipientList = async () => {
      let data = await fetchData({
        url: api.recipient_list_not_publish + route.params.id + "/pending",
        method: "get",
      });
      if (!data.hasError) {
        state.recipient = data.response.data.recipient;
      }
    };

    const addRecipient = async () => {
      if (state.form.addRecipient.recipients.length < 1) {
        state.form.addRecipient.recipients = state.temp;
      } else {
        state.form.addRecipient.recipients = state.temp;
      }
      state.isFormLoading = true;
      let res = await fetchData({
        url: api.recipient_create,
        method: "post",
        data: state.form.addRecipient,
      });
      if (!res.hasError) {
        state.temp = {};
        state.add = false;
      } else {
        state.hasFormError = true;
        state.message = res.response.data.message;
      }
      state.isFormLoading = false;
      getRecipientList();
      getDataById();
    };

    onMounted(() => {
      getDataById();
      getRecipientList();
    });

    const downloadExample = async () => {
      let data = await fetchData({
        url: api.download_example_excel,
        data: {
          design_id: state.data.design_id,
        },
        method: "post",
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([data.response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    };

    const dragDrop = (e) => {
      if (
        e.dataTransfer.files[e.dataTransfer.files.length - 1].type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        state.hasFormError = true;
        state.message = "File harus berupa excel";
      } else {
        state.fileImport =
          e.dataTransfer.files[e.dataTransfer.files.length - 1];
      }
    };

    const submitExcel = async () => {
      state.isFormLoading = true;
      let formData = new FormData();
      formData.append("template_id", route.params.id);
      formData.append("file_upload", state.fileImport);
      let res = await fetchData({
        url: api.recipient_import,
        method: "post",
        data: formData,
      });
      if (!res.hasError) {
        state.isFormLoading = false;
        state.modalImport = false;
        getDataById();
        getRecipientList();
      } else {
        state.hasFormError = true;
        state.message = res.response.data.message;
      }

      state.isFormLoading = false;
    };

    const handleFileUpload = (e) => {
      if (
        e.target.files[0].type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        state.hasFormError = true;
        state.message = "File harus berupa file excel";
      } else {
        state.hasFormError = false;
        state.fileImport = e.target.files[0];
        state.message = "";
      }
    };

    return {
      downloadExample,
      handleFileUpload,
      submitExcel,
      dragDrop,
      props,
      getTotalRecipient,
      addRecipient,
      deleteRecipient,
      publishCertificate,
      openEdit,
      editRecipient,
      closeEdit,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss">
.dragDrop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20rem;
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &:hover {
    border: 2px solid #ccc;
  }
}
</style>
