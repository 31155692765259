<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="d-flex justify-content-between text-black pt-0 pb-0 px-0 fs-20 font-weight-bold title">
      <div>
        <img src="@/assets/images/fi-ss-document.svg" class="mr-2">Surat Perjanjian Kerjasama
        <template v-if="backHome">
          <v-chip
            class="ma-2"
            color="success"
            outlined
          >
            Completed
          </v-chip>
        </template>
        <template v-else>
          <v-chip
            class="ma-2"
            color="orange"
            outlined
          >
            Menunggu tandatangan anda
          </v-chip>
        </template>
      </div>

      <div>
        <template v-if="backHome">
          <v-btn color="primary" to="/dashboard">
            Kembali ke Homepage
          </v-btn>
        </template>
      </div>
    </v-card-title>

    <v-card-title class="div-sign">
      <template v-if="lanjutkan">
        <v-btn color="primary" @click="dialog = true">
          Lanjutkan
        </v-btn>
      </template>
      <template v-if="startSign">
        <v-btn color="primary" @click="dialogSign = true">
          Mulai Tandatangan
        </v-btn>
      </template>
      <template v-if="backHome">
        <v-btn color="secondary" disabled>
          Selesai Ditandatangani
        </v-btn>
      </template>
    </v-card-title>

    <v-row>
      <v-col cols="12">
        <v-card-text class="px-0">
          <v-card class="mx-10">
            <v-row class="text-center">
              <v-col>
                <!-- <iframe src="https://corona.sumbarprov.go.id/images/2020/04/file/KONTRAK_TENAGA_COVID-19pdf_(2).pdf" width="100%" height="800px"></iframe> -->
                <iframe 
                  :src="locFrame"
                  width="540px"
                  height="700px"
                >
                </iframe>
              </v-col>
            </v-row>
          </v-card>

          <v-snackbar v-model="isError">
            {{ showMessage }}
            <template v-slot:action="{attrs}">
              <v-btn color="pink" text v-bind="attrs" @click="isError = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-card-text>
      </v-col>
    </v-row>

    <!-- dialog TTD -->
    <v-dialog v-model="dialogSign" width="700" persistent>
      <v-card class="">
        <div class="text-end">
          <v-btn
            icon
            dark
            @click="dialogSign = false"
            color="#333"
            class="float-right"
          >
            <v-icon>ri-close-fill</v-icon>
          </v-btn>
        </div>

        <v-card-text class="my-3 text-left py-5">
          <v-row>
            <v-col cols="12" sm="11" class="mx-auto">
              <p class="text-black fs-16 font-weight-medium">
                Tambahkan tanda tangan
              </p>
              <form @submit.prevent="handleSignature()">
                <v-card
                  style="min-height:222px;"
                  elevation="2"
                ></v-card>

                <v-checkbox
                  class="agree-sign"
                  v-model="checkbox"
                  :label="`Dengan membubuhkan tandatangan, saya setuju bahwa gambar ini adalah representasi elektronik dari tandatangan yang saya gunakan. Saya setuju bahwa penerima juga akan dapat melihat tandatangan saya beserta seluruh data saya, termasuk di dalamnya nama alamat email`"
                ></v-checkbox>

                <v-btn
                  color="primary"
                  dark
                  type="submit"
                  class="mt-4"
                  :loading="isLoading"
                >
                  Simpan
                </v-btn>
              </form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialog PIN -->
    <v-dialog v-model="dialog" width="700" persistent>
      <v-card class="">
        <div class="text-end">
          <v-btn
            icon
            dark
            @click="dialog = false"
            color="#333"
            class="float-right"
          >
            <v-icon>ri-close-fill</v-icon>
          </v-btn>
        </div>

        <v-card-text class="my-3 text-center py-5">
          <img src="@/assets/images/img-otp.png" class="mb-3" />
          <p class="text-black fs-16 font-weight-medium">
            Please input your PIN
          </p>
          <v-row>
            <v-col id="kode" cols="12" sm="8" class="mx-auto">
              <form @submit.prevent="handleSubmit()" ref="pinForm">
                <PincodeInput autocomplete="false" autofocus v-model="code" secure change="" :value="code" :length="6" />

              <v-btn
                color="primary"
                dark
                type="submit"
                class="mt-4"
                :loading="isLoading"
              >
                Continue
              </v-btn>
              </form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialog loading -->
    <v-dialog v-model="dialogLoading" width="700" persistent>
      <v-card>
        <v-icon
          class="px-4 py-4"
          small
          color="#CFD8DC"
          @click="dialogLoading = false"
          >ri-close-line</v-icon
        >
        <v-row class="my-0 mx-0 px-5 py-5">
          <v-col cols="12">
            <div
              class="d-flex align-center justify-content-center"
              style="gap: 20px;"
            >
              <img src="@/assets/images/biru.png" />
              <img src="@/assets/images/loading.png" />
              <img src="@/assets/images/orange.png" />
            </div>
            <div class="mt-5 text-center">
              <p class="font-weight-bold mb-2 text-black">
                Kami sedang mengecek Transferanmu
              </p>
              <p class="font-weight-thin mb-0 text-black">
                It may take a while to complete the transaction
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// import pdf from 'vue-pdf'
// import {reactive, toRefs, onMounted} from "@vue/composition-api";
// import axios from "axios";
import {formatPrice} from "@/lib/formatDate";
import PincodeInput from "vue-pincode-input";

export default {
  setup() {
    // const state = reactive({
      
    // });

    return {
      // ...toRefs(state),
    };
  },
  components: {
    PincodeInput
  },
  data: () => ({
    valid: true,
    dialogLoading: false,
    dialogSign: false,
    dialog: false,
    isError: false,
    isLoading: false,
    lanjutkan: false,
    startSign: true,
    backHome: false,
    checkbox: false,
    submiting: false,
    showMessage: "",
    disabled: false,
    showBankList: false,
    paramOtp: {
      disbursement_id: "",
    },
    code: "",
    dataDetail: {},
    nameRules: [
      (v) => !!v || "Pin is required",
      (v) => (v && v.length == 6) || "Pin must be 6 characters",
    ],
    serverParams: {
      disbursement_id: "",
      otp_code: "",
      payment_channel: "",
    },
    listBank: [],
    focusIndex: 0,
    locFrame: 'https://pensign-webview.vercel.app/document?id=2&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNiMzA3MTIwLTViYzgtMTFlYy05M2M2LTc5YzFlNzkyMWZjYiIsImJ1c2luZXNzX2lkIjoiM2IzMDcxMjEtNWJjOC0xMWVjLTkzYzYtNzljMWU3OTIxZmNiIiwicm9sZXMiOltdLCJpYXQiOjE2MzkzNzExMTEsImV4cCI6MTY0MDIzNTExMX0.PaiLZrQGVW4NfcRaJuyNIoIY1YuGi8o_FhTaHfD8RBA'
  }),
  mounted() {
    
  },
  watch: {
    code() {
      if(this.code.length === 6 ) this.handleSubmit()
    }
  },
  computed: {
    idParam: {
      get() {
        return this.$route.params.id;
      },
    },
  },
  methods: {
    async confirmTransfer() {
      this.dialog = true;
    },

    async handleSubmit() {
      this.dialogLoading = true;
      setTimeout(()=> {
        this.code = ""
      }, 2000)
      this.documentCompleted();
    },

    async documentCompleted() {
      setTimeout(()=> {
        this.backHome = true;
        this.lanjutkan = false;
        this.dialog = false;
        this.dialogLoading = false;
      }, 3000)
    },

    checkBankTransfer(type) {
      if (type === "transfer") {
        this.disabled = true;
        this.showBankList = true;
      }
    },

    handleSignature() {
      this.lanjutkan = true;
      this.startSign = false;
      this.isError = true;
      this.showMessage = "Anda telah menyelesaikan permintaan tandatangan. Klik tombol Lanjutkan untuk memproses dokumen"
      this.dialogSign = false;
    },

    formatPrice(value) {
      return formatPrice(value);
    },

    validateField() {
      this.$refs.form.validate();
    },

    onDeletePin() {
      this.$refs.pinForm[this.focusIndex - 2].focus();
    },

    onInputPin() {
      if (this.code.length === 6) this.handleSubmit();
    },
  },
};
</script>

<style scoped lang="scss">
.justify-content-between {
  justify-content: space-between;
}
.title {
  background-color: #fff;
  padding: 1rem 2rem !important;
  margin: -1rem -1.4rem 0 -1.4rem !important;
}
.div-sign {
  background-color: #EDEDED;
  padding: 1rem 2rem !important;
  margin: 0rem -1.4rem 1rem -1.4rem !important;
  justify-content: end;
}
div.vue-pincode-input-wrapper {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  * {
    border-bottom: solid 2px #e4e4e7 !important;
    padding: 30px 10px !important;
    font-size: 20px !important;
    text-align: center;
  }
  input.vue-pincode-input {
    border-bottom: solid 2px #e4e4e7 !important;
    padding: 30px 10px !important;
    font-size: 20px !important;
    text-align: center;
    max-width: auto;
    padding: 27px;
  }
}
input.vue-pincode-input {
  border-bottom: solid 2px #e4e4e7 !important;
  padding: 30px 10px !important;
  font-size: 20px !important;
  text-align: center;
  max-width: auto;
  padding: 27px;
}

.justify-content-end {
  justify-content: end;
}
.justify-content-center {
  justify-content: center;
}
.bg-grey {
  background-color: #f9f9f9;
}
.v-radio.type_fund.v-radio--is-disabled {
  border-color: #e0e0e0;
  background-color: #bdbdbd;
  color: #eeeeee !important;
}
.v-radio.type_fund.v-item--active {
  border-color: #0068d6;
  background-color: #0068d6;
  color: #fff !important;
}
.v-radio.type_fund:hover {
  border-color: #0159b6;
  background-color: #0159b6;
  color: #fff !important;
}

.v-radio.type_fund {
  padding: 10px 20px 10px 10px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
}
.list-paket {
  list-style: none;
  padding-left: 0;
}
.list-paket li {
  margin-bottom: 5px;
}
</style>

<style>
.v-radio.type_fund .v-input--selection-controls__input {
  display: none !important;
}
.type_fund .theme--light.v-label {
  color: inherit;
}

.v-radio.deposit.theme--light.v-item--active {
  border: 2px solid #409eff;
}
.v-radio.deposit:hover {
  border-color: #409eff;
}
.v-radio.deposit .v-input--selection-controls__input {
  display: none;
}
.v-radio.deposit {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
}
.v-radio.deposit img {
  width: 100px;
}
.deposit .theme--light.v-label {
  color: inherit;
}
.d-flex.list-bank .v-input {
  margin: 0;
}
div.agree-sign .v-input__slot {
  align-items: flex-start !important;
}
</style>
