<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">
      <div class="row">
        <div class="col-md-6 mx-auto text-center">
          <p class="text-h4 text-black pt-0 px-0 mb-0 pb-0">Paket Subskripsi Anda</p>
        </div>
      </div>
    </v-card-title>

    <v-card class="pa-2 ma-3" elevation="2">
      <v-card-text class="pb-2">
        <h1 class="activate-complete__message-title">
          Paket Anda
        </h1>
        <p class="activate-complete__message-subtitle">
          {{ dataCount.name }} - {{ dataCount.max_user }} user
        </p>
        <p>Berlaku Hingga: {{ formatToDate(dataCount.duration_package) }}</p>
      </v-card-text>
    </v-card>

    <v-card-text class="px-0">
      <div>
        <div class="table">
          <v-card>
            <v-card-title class="font-weight-regular">
              Riwayat Invoice
            </v-card-title>

            <div v-if="!invoice" class="col-md-6 mx-auto my-2 text-center">
              <img src="@/assets/images/empty.png" />
              <p class="font-weight-bold text-black mb-2">No data available.</p>
            </div>

            <v-data-table
              v-else
              :headers="headers"
              :items="invoice"
              :search="search"
              :loading="isLoading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.external_id`]="{ item }">
                <a :href="item.extra.invoice_url" target="_blank">{{ item.external_id }}</a>
              </template>

              <template v-slot:[`item.package_active`]="{ item }">
                {{ item.order_datetime }}
              </template>

              <template v-slot:[`item.plan_name`]="{ item }">
                {{ item.plan_name }}
              </template>

              <template v-slot:[`item.plan_price`]="{ item }">
                <!-- <v-avatar
                  color="grey"
                  size="36"
                >
                  <span class="white--text text-h7 text-uppercase">{{item.roles[0]}}</span>
                </v-avatar> -->
                {{ item.plan_price }}
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <!-- @click="getReset(item.id)" -->
                <div v-if="item.status === 'EXPIRED'">
                  <v-chip color="warning" class="text-center my-3" small>
                    {{ item.exp }}
                  </v-chip>
                </div>
                <div v-else-if="item.status === 'PENDING'">
                  <v-chip color="primary" class="text-center my-3" small>
                    {{ item.status }}
                  </v-chip>
                  <p class="text-red mt-0">
                    {{ item.exp }}
                  </p>
                </div>
                <v-chip v-else color="success" class="text-center my-3" small>
                  {{ item.status }}
                </v-chip>

                <!-- <v-chip
                  v-if="item.status === 'kyc completed'"
                  color="#43A047"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'registered'"
                  color="#3949AB"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'verified'"
                  color="#FF6F00"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'review kyc'"
                  color="#F0AB0B"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip> -->
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <!-- <a :href="{ name: 'detail-paket', params: {id: item.id} }" target="_blank">invoice</a>                 -->
                <router-link
                  :to="{ name: 'detail-subscription', params: { id: item.id } }"
                  class="text-underline"
                  >Invoice</router-link
                >
              </template>
            </v-data-table>
          </v-card>

          <v-snackbar v-model="isError">
            {{ showMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn color="primary" text v-bind="attrs" @click="isError = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
// import store from "../../store";
import { onMounted, reactive, toRefs } from '@vue/composition-api';

// import {reactive, toRefs} from "@vue/composition-api";
import { formatDate } from '@/lib/formatDate';
// import { formatDateTime } from "@/lib/formatDate";

import Vue from 'vue';

const headers = [
  { text: 'No Invoice', value: 'external_id', class: 'bg-grey' },
  { text: 'Tanggal Pembelian', value: 'package_active', class: 'bg-grey' },
  { text: 'Nama Paket', value: 'plan_name', class: 'bg-grey' },
  { text: 'Harga', value: 'plan_price', class: 'bg-grey' },
  { text: 'Status', value: 'status', class: 'bg-grey' },
  { text: '', value: 'action', class: 'bg-grey' },
  // { text: 'ACTION', value: 'action', class: "bg-grey" },
  // { text: 'STATUS KYC', value: 'status', class: "bg-grey" },
];

// const listUser = [
//   {
//     createdAt: '22 Jul 2021',
//     timeAt: '13:22:24',
//     username: 'Siti Sulaiman',
//     email: 'lorem@email.com',
//     role: 'Admin',
//     status: 'kyc completed',
//   },
//   {
//     createdAt: '22 Jul 2021',
//     timeAt: '13:22:24',
//     username: 'Paimin',
//     email: 'lorem@email.com',
//     role: 'User',
//     status: 'registered',
//   },
//   {
//     createdAt: '22 Jul 2021',
//     timeAt: '13:22:24',
//     username: 'Sunaryo',
//     email: 'lorem@email.com',
//     role: 'User',
//     status: 'registered',
//   },
//   {
//     createdAt: '22 Jul 2021',
//     timeAt: '13:22:24',
//     username: 'Siti Sulaiman',
//     email: 'lorem@email.com',
//     role: 'User',
//     status: 'verified',
//   },
//   {
//     createdAt: '22 Jul 2021',
//     timeAt: '13:22:24',
//     username: 'Muhammad Iqbal',
//     email: 'lorem@email.com',
//     role: 'Admin',
//     status: 'review kyc',
//   },
// ];

const ex = ['Admin', 'Developer', 'User', 'Reviewer'];

const states = {
  search: '',
  dialog: false,
  resetPass: false,
  isLoading: false,
  id: null,
  isError: false,
  showMessage: '',
  submiting: false,
  emailRules: [(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
  serverParams: {
    email: '',
  },
  columnFilter: {
    status: [],
  },
  listUser: [],
  roles: [],
  limit: 10,
  page: 1,
  total_data: null,
  email: '',
  password: '',
  fullname: '',
  role: '',
  valid: true,
  errors: null,
  show: false,
};

const formatToDate = (value) => {
  return formatDate(value);
};

// const = (value) => {
//   let a = JSON.parse(value)
//   return a.invoice_url
// };

export default {
  setup() {
    const state = reactive(states);

    const filterStatus = async () => {
      state.isLoading = true;
      let { data: response } = await axios.get(
        `/subscription/history?page=${state.page}&sort&limit=${state.limit}`
        // `https://api.pensign.id/v1/auth/users/all`
      );
      // {{base_url}}/v1/auth/users/list?email=manghaku@gmail.com&page=1&sort&limit=2
      // console.log('test',response)
      state.listUser = response.data;
      // state.limit=response.data.pagination.total_display;
      // state.page=response.data.pagination.current;
      // state.total_data=response.data.pagination.total_data;
      state.isLoading = false;
    };

    onMounted(async () => {
      state.limit = null;
      state.page = null;
      filterStatus();
      //listUser();
    });

    return {
      ...toRefs(state),
      headers,
      //listUser,
      ex,
      formatToDate,
      //
      filterStatus,
    };
  },
  data() {
    return {
      dataCount: {},
      invoice: [],
    };
  },
  mounted() {
    this.getRoles();
    this.getCounter();
    this.getInvoice();
    setInterval(this.getExpiryCounter, 1000);
  },
  methods: {
    getInvoice() {
      this.axios.get(`/subscription/history`).then((response) => {
        let res = response.data.data;
        this.invoice = res;
      });
    },
    getExpiryCounter: function() {
      for (var idx in this.invoice) {
        var currentTime = Date.now();
        var timer = new Date(this.invoice[idx].expiry_date).getTime() - currentTime;
        // var timer = new Date(formatDateTime(this.invoice[idx].expiry_date)).getTime() - currentTime;

        var day = timer / 1000 / 60 / 60 / 24;
        // get hour
        var hour = (day % 1) * 24;
        // get min
        var min = (hour % 1) * 60;
        // get sec
        //var sec = (min % 1) * 60;

        var appndDate = Math.floor(hour) + ' jam, ' + Math.floor(min) + ' menit ';

        if (timer > 0) {
          Vue.set(this.invoice[idx], 'exp', appndDate);
        } else {
          Vue.set(this.invoice[idx], 'exp', 'EXPIRED');
        }
      }
    },
    getList() {
      this.axios.get('/subscription/history').then((response) => {
        let res = response.data.data.user;
        this.listUser = res;
      });
    },
    getCounter() {
      this.axios.get('/subscription/count').then((response) => {
        let res = response.data.data;
        this.dataCount = res;
      });
    },
    getRoles() {
      this.axios.get('/auth/roles/all').then((response) => {
        let res = response.data.data;
        this.roles = res;
      });
    },
    submit() {
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.submiting = true;
          let data = {
            fullname: this.fullname,
            email: this.email,
            password: this.password,
            role: this.role,
          };

          this.axios
            .post(`auth/dashboard/add_user`, data, {
              headers: { 'Content-Type': 'application/json' },
            })
            .then(() => {
              this.submiting = false;
              this.dialog = false;
              this.showMessage = 'Success add member!';
              this.isError = true;
              setTimeout(() => {
                this.isError = false;
                this.showMessage = null;
              }, 3000);
              this.getList();
            })
            .catch(() => {
              this.submiting = false;
              this.$refs.form.reset();
              this.isError = true;
              this.showMessage = 'Failed add member!';
              setTimeout(() => {
                this.isError = false;
                this.showMessage = null;
              }, 3000);
            });
        }
      }, 50);
    },
    getReset(id) {
      // console.log(id);
      this.id = id;
      this.resetPass = true;
    },
    reset() {
      let data = {
        user_id: this.id,
      };
      this.axios
        .post(`auth/dashboard/reset_password`, data, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then(() => {
          this.submiting = false;
          this.isError = true;
          this.showMessage = 'Success reset password!';
          this.resetPass = false;
          setTimeout(() => {
            this.isError = false;
            this.showMessage = null;
          }, 3000);
        })
        .catch(() => {
          this.submiting = false;
          this.$refs.form.reset();
          this.isError = true;
          this.showMessage = 'Failed reset password!';
          setTimeout(() => {
            this.isError = false;
            this.showMessage = null;
          }, 3000);
        });
    },
  },
};
</script>

<style>
.bg-grey {
  background-color: #f9f9f9;
}
</style>
