<!-- @format -->

<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold"
      >Request Certificate Design</v-card-title
    >
    <v-card-text class="px-0">
      <div class="content">
        <div class="table my-3">
          <form @submit.prevent="handleSubmit()" enctype="multipart/form-data">
            <v-card class="mt-5">
              <v-card-title class="font-weight-regular">
                <p class="ms-4 fs-16 text-black font-weight-medium mb-0">
                  Data Perusahaan
                </p>
                <div class="col-md-12 px-0">
                  <v-row>
                    <v-col cols="4">
                      <v-subheader class="text-black font-weight-thin fs-14"
                        >Logo</v-subheader
                      >
                    </v-col>
                    <v-col cols="8">
                      <v-file-input
                        @change="uploadLogo"
                        :prepend-icon="null"
                        prepend-inner-icon="ri-upload-cloud-fill"
                        type="file"
                        accept="image/*"
                        placeholder="Drag and drop your file here or choose file to add file"
                      >
                      </v-file-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-subheader class="text-black font-weight-thin fs-14"
                        >Nama Perusahaan/Organisasi</v-subheader
                      >
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-model="form.organisasi"
                        outlined
                        class=""
                        placeholder="Nama Perusahaan/Organisasi"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-subheader class="text-black font-weight-thin fs-14"
                        >Nama Pimpinan/Penanggungjawab</v-subheader
                      >
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-model="form.penanggungJawab"
                        outlined
                        class=""
                        placeholder="Nama Pimpinan/Penanggungjawab"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div class="col-md-12 px-0 py-4">
                  <v-divider></v-divider>
                </div>
                <p class="ms-4 fs-16 text-black font-weight-medium mb-0">
                  Konten Sertifikat
                </p>
                <div class="col-md-12">
                  <v-row>
                    <v-col cols="4">
                      <v-subheader class="text-black font-weight-thin fs-14"
                        >Text Utama</v-subheader
                      >
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        outlined
                        class=""
                        placeholder="Text Utama"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-subheader class="text-black font-weight-thin fs-14"
                        >Text Pendukung</v-subheader
                      >
                    </v-col>
                    <v-col cols="8">
                      <v-textarea
                        v-model="form.certificate.textPendukung"
                        outlined
                        class=""
                        placeholder="Text Utama"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-subheader class="text-black font-weight-thin fs-14"
                        >Tanggal Sertifikat</v-subheader
                      >
                    </v-col>
                    <v-col cols="8">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        elevation="0"
                      >
                        <v-date-picker
                          ref="picker"
                          v-model="form.certificate.tanggalSertifikat"
                          min="1950-01-01"
                          :max="new Date().toISOString()"
                          outlined
                          required
                        ></v-date-picker>
                        <template v-slot:activator="{on, attrs}">
                          <v-text-field
                            v-model="form.certificate.tanggalSertifikat"
                            append-icon="ri-calendar-line"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            class=""
                            placeholder="DD-MM-YYYY"
                          ></v-text-field>
                        </template>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-subheader class="text-black font-weight-thin fs-14"
                        >Nomor Sertifikat</v-subheader
                      >
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-model="form.certificate.nomorSertifikat"
                        outlined
                        class=""
                        placeholder="Nomor Sertifikat"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-subheader class="text-black font-weight-thin fs-14"
                        >Penandatangan</v-subheader
                      >
                    </v-col>
                    <v-col cols="8">
                      <div>
                        <div
                          class="d-flex my-2"
                          v-for="(signature, i) in form.certificate.signatures"
                          :key="i"
                        >
                          <v-text-field
                            outlined
                            v-model="signature.name"
                            class="mr-2"
                            placeholder="Nama Lengkap"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            v-model="signature.position"
                            class="mr-2"
                            placeholder="Gelar/Jabatan"
                          ></v-text-field>
                        </div>
                        <v-btn
                          @click="addSignature()"
                          class="text-right"
                          color="primary"
                          elevation="0"
                          outlined
                        >
                          <v-icon class="mr-1">ri-error-warning-fill</v-icon>
                          Tambah TTD
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div class="col-md-12 px-0 py-4">
                  <v-divider></v-divider>
                </div>
                <p class="ms-4 fs-14 text-black font-weight-medium mb-0">
                  Upload Background
                </p>
                <div class="col-md-12">
                  <v-row>
                    <v-col cols="4" class="ps-0">
                      <v-subheader class="font-weight-thin fs-14 wb"
                        >Silahkan upload Background jika anda telah punya desain
                        sendiri. format .jpg, .svg, atau .png</v-subheader
                      >
                    </v-col>
                    <v-col cols="8">
                      <v-file-input
                        :prepend-icon="null"
                        @change="uploadBG"
                        prepend-inner-icon="ri-upload-cloud-fill"
                        type="file"
                        accept="image/*"
                        placeholder="Drag and drop your file here or choose file to add file"
                      >
                      </v-file-input>
                    </v-col>
                  </v-row>
                </div>
              </v-card-title>
              <div class="col-md-12 px-0 py-4">
                <v-divider></v-divider>
              </div>
              <div class="col-md-12">
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14">
                      Catatan Tambahan
                    </v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-textarea
                      v-model="form.message"
                      outlined
                      class=""
                      placeholder="Contoh: Logo taruh kanan atas, "
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
              <div class="col-md-12">
                <v-row>
                  <v-col md="6">
                    <v-btn class="mr-2" color="primary" elevation="0" outlined>
                      <v-icon class="mr-1">ri-question-line</v-icon> Ada
                      Pertanyaan? Hubungi Kami
                    </v-btn>
                  </v-col>
                  <v-col md="6" class="align-end text-end">
                    <v-btn
                      class="text-right mr-2 px-8"
                      color="primary"
                      elevation="0"
                      :to="{
                        name: 'list-template',
                      }"
                      outlined
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      class="text-right px-8"
                      color="primary"
                      elevation="0"
                      type="submit"
                    >
                      Request Desain
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </form>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {reactive, toRefs} from "@vue/composition-api";
import useFetch from "../../../hooks/useFetch";
import api from "../../../store/api";

export default {
    created() {
    document.title = "Request Design-Pensign Admin";
  },
  data: () => ({
    dialog: false,
    menu: false,
    date: null,
    type: "",
    items: [
      {
        text: "E-Certificate",
        disabled: false,
        href: "#",
      },
      {
        text: "Template",
        disabled: false,
        href: "/list-template",
      },
      {
        text: "Create Template",
        disabled: true,
        href: "#",
      },
    ],
  }),
  setup() {
    const {fetchData} = useFetch();
    const uploadLogo = (file) => {
      state.form.logo = file;
      let reader = new FileReader();
      console.log(state.form.logo, "stateform");
      reader.readAsDataURL(file);
      reader.onload = () => {
        state.previewLogo = URL.createObjectURL(file);
      };
    };
    const uploadBG = (file) => {
      state.form.backgroundCertificate = file;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        state.previewBG = URL.createObjectURL(file);
      };
    };
    const state = reactive({
      previewBG: null,
      previewLogo: null,
      form: {
        logo: "",
        organisasi: "",
        penanggungJawab: "",
        certificate: {
          textUtama: "",
          textPendukung: "",
          tanggalSertifikat: "",
          nomorSertifikat: "",
          signatures: [
            {
              name: "",
              position: "",
            },
          ],
          backgroundCertificate: "",
          pesan: "",
        },
      },
    });

    const addSignature = () => {
      state.form.certificate.signatures.push({
        name: "",
        position: "",
      });
    };

    const handleSubmit = async () => {

      const formData = new FormData()
      
      formData.append('logo', state.form.logo)
      formData.append('organisasi', state.form.organisasi)
      formData.append('penanggungJawab', state.form.penanggungJawab)
      formData.append('textUtama', state.form.certificate.textUtama)
      formData.append('textPendukung', state.form.certificate.textPendukung)
      formData.append('tanggalSertifikat', state.form.certificate.tanggalSertifikat)
      formData.append('nomorSertifikat', state.form.certificate.nomorSertifikat)
      formData.append('backgroundCertificate', state.form.backgroundCertificate)
      formData.append('signatures', JSON.stringify(state.form.certificate.signatures))
      formData.append('pesan', state.form.pesan)


      let res = await fetchData({
        url: api.design_request,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(res, "res");
    };

    return {
      handleSubmit,
      addSignature,
      uploadLogo,
      uploadBG,
      ...toRefs(state),
    };
  },
};
</script>

<style>
.preview {
  min-height: 6em;
  width: 100%;
}
</style>
