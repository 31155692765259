<template>
  <v-card rounded="lg" class="transparent">
    <v-card-text class="px-0">
      <v-row>
        <v-col md="6" offset-md="3">
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">
              <div class="row">
                <div class="col-md-12">
                  <h4 class="text-black font-weight-bold">Invoice</h4>
                  <p class="text-uppercase">INVOICE NO. DFT49-089</p>
                </div>
              </div>
              <div class="col-md-12 px-0">
                <h1 class="text-black font-weight-bold">Rp. 650.000</h1>
              </div>
              <div class="row my-3">
                <div class="col-md-6">
                  <p class="text-uppercase mb-0">ISSUED DATE</p>
                  <h5 class="text-black font-weight-bold">4 Agustus 2021, 07:20:20 AM</h5>
                </div>
                <div class="col-md-6">
                  <p class="text-uppercase mb-0">DUE DATE</p>
                  <h5 class="text-black font-weight-bold">4 Agustus 2021, 07:20:20 AM</h5>
                </div>
              </div>
              <div class="col-md-12 px-0">
                <p class="text-uppercase mb-0">BILLED TO</p>
                <h5 class="text-black font-weight-bold">Cameron Williamson</h5>
                <h5 class="text-black font-weight-bold">cameron.will@gmail.com</h5>
              </div>
            </v-card-title>

            <template>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr class="text-uppercase">
                      <th class="text-left">item</th>
                      <th class="text-left">qty</th>
                      <th class="text-left">price</th>
                      <th class="text-left">total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-capitalize">cireng</td>
                      <td>20</td>
                      <td>Rp 20.000</td>
                      <td>Rp 420.000</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="3" class="text-left text-capitalize font-weight-bold">Total Amount</td>
                      <td class="text-left text-capitalize font-weight-bold">Rp. 500.000</td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </template>

            <v-card-text>
              <div class="row mt-4">
                <div class="col-md-12">
                  <div class="px-4 py-4" style="background-color: #F6F9FF;">
                    <h2 class="fpnt-weight-bold mb-3">Additional Notes.</h2>
                    <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis dolores aperiam cupiditate inventore enim vero?</p>
                  </div>

                  <v-btn
                    block
                    depressed
                    color="primary"
                    class="my-5"
                  >
                    Pay Invoice
                  </v-btn>
                </div>
              </div>
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  
    <div class="my-5 text-center">
      <img src="@/assets/images/img-logo-black.png" width="200" />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "receiveEmailInvoice",
  data: () => ({
    menu:false,
    ex: ['1','2','3'],
    search: '',
    headers: [          
      { text: 'CREATED DATE', value: 'createdAt' },
      { text: 'TRANSACTION ID', value: 'code' },
      { text: 'DESCRIPTION', value: 'description' },
      { text: 'CUSTOMER EMAIL', value: 'email' },
      { text: 'CHANNEL', value: 'payment_method' },
      { text: 'AMOUNT', value: 'total_amount' },
      { text: 'STATUS', value: 'status' },
    ],
    desserts: [
      {
        date: '22 Jul 2021',
        id: '006024382197241',
        desc: 'Fixed Salary',
        cm: 'Karyawan Fix',
        channel:'BCA',
        amount: 'Rp. 20.000.000',
        status: 'Complete',
      },
      {
        date: '22 Jul 2021',
        id: '006024382197242',
        desc: 'Recurring Bulk Disbursement for some vendors',
        cm: 'Karyawan Fix',
        channel:'BCA',
        amount: 'Rp. 20.000.000',
        status: 'Refunded',
      },
      {
        date: '22 Jul 2021',
        id: '006024382197243',
        desc: 'Fixed Salary',
        channel:'BCA',
        cm: 'Recurring Bulk Disbursement for principal',
        amount: 'Rp. 20.000.000',
        status: 'Canceled',
      },
      {
        date: '22 Jul 2021',
        id: '006024382197244',
        desc: 'Fixed Salary',
        channel:'BCA',
        cm: 'Recurring Bulk Disbursement for principal',
        amount: 'Rp. 20.000.000',
        status: 'Pending',
      },
    ],
    dates:[],
  }),
  mounted() {
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
  },
  methods: {
  },
};
</script>

<style>
</style>