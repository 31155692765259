<!-- @format -->

<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold"
      >Create Template</v-card-title
    >
    <v-card-text class="px-0">
      <div class="content">
        <div class="table my-3">
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">
              <p class="ms-4 fs-16 text-black font-weight-medium mb-0">
                Certificate Info
              </p>
              <div class="col-md-12 px-0">
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14"
                      >Certificate Title</v-subheader
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      outlined
                      :rules="[rules.required]"
                      v-model="form.title"
                      placeholder="Certificate title"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14"
                      >Description</v-subheader
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-textarea
                      :rules="[rules.required]"
                      v-model="form.description"
                      outlined
                      class=""
                      placeholder="Description"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
              <div class="col-md-12 px-0 py-4">
                <v-divider></v-divider>
              </div>
              <p class="ms-4 fs-16 text-black font-weight-medium mb-0">
                Select Type
              </p>
              <div class="col-md-12">
                <v-radio-group
                  @change="getDesignByType()"
                  v-model="form.type"
                  :rules="[rules.required]"
                  column
                >
                  <v-radio
                    label="Degree"
                    color="success"
                    value="Degree"
                  ></v-radio>
                  <v-radio
                    label="Certificate"
                    color="success"
                    value="Certificate"
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-card-title>
            <div class="col-md-12 px-0 py-4">
              <v-divider></v-divider>
            </div>
            <div v-show="form.type === 'Certificate'" class="template px-2">
              <div class="col-md-12 px-0 py-4">
                <v-divider></v-divider>
              </div>
              <p class="ms-4 fs-14 text-black font-weight-medium mb-0 py-4">
                Select Design
              </p>
              <div class="col-md-12 px-0 py-4">
                <v-divider></v-divider>
              </div>
              <div class="row my-10">
                <div v-for="(d, i) in designs" :key="i" class="col-md-3">
                  <label :for="d.id">
                    <input
                      class="select-design"
                      :id="d.id"
                      type="radio"
                      v-model="form.design_id"
                      :value="d.id"
                      :rules="[rules.required]"
                    />
                    <v-card
                      @click="changeIndex(d.id)"
                      class="design"
                      elevation="1"
                    >
                      <img :src="d.preview" class="" style="width: 100%;" />
                      <v-card-text class="py-1 px-3">
                        <p class="s-16 text-black font-weight-medium">
                          {{ d.name }}
                        </p>
                      </v-card-text>
                    </v-card>
                  </label>
                </div>
              </div>
              <v-divider />
              <div class="row" v-show="form.design_id">
                <div class="col-md-12">
                  <p class="ms-4 fs-14 text-black font-weight-medium mb-0 py-4">
                    Input Signature
                  </p>
                  <p class="fs-14 ms-4">
                    Add a signature for the credential template. Credential has
                    at least 1 signer.
                  </p>
                </div>
                <div class="col-md-12 px-0">
                  <v-row
                    v-for="(signature, i) in form.fields.credentialSubject
                      .issuance.authorizedSignature"
                    :key="i"
                  >
                    <v-col cols="4">
                      <v-subheader class="text-black font-weight-thin fs-14"
                        >Signature {{ i + 1 }}</v-subheader
                      >
                    </v-col>
                    <v-col cols="8">
                      <div class="d-flex">
                        <v-text-field
                          :rules="[rules.required]"
                          v-model="signature.name"
                          outlined
                          class="mr-2"
                          placeholder="Name"
                        ></v-text-field>
                        <v-text-field
                          :rules="[rules.required]"
                          outlined
                          v-model="signature.position"
                          placeholder="Position"
                        ></v-text-field>
                        <div>
                          <v-btn
                            class="text-right d-block ml-2"
                            style="height: 100%;"
                            outlined
                            color="red"
                            @click="removeSignature(i)"
                          >
                            x
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <div class="col-md-12 px-0 py-4">
                      <v-divider></v-divider>
                    </div>
                    <v-col cols="12" class="align-center text-center">
                      <v-btn
                        class="text-right px-8"
                        color="primary"
                        outlined
                        elevation="0"
                        @click="addSignature()"
                      >
                        Add Signature
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>

            <div v-show="form.type === 'Degree'" class="template px-2">
              <p class="ms-4 fs-14 text-black font-weight-medium mb-0 py-4">
                Select Design
              </p>
              <div class="col-md-12 px-0 py-4">
                <v-divider></v-divider>
              </div>
              <div class="row my-10">
                <div v-for="(d, i) in designs" :key="i" class="col-md-3">
                  <label :for="d.id">
                    <input
                      :rules="[rules.required]"
                      class="select-design"
                      :id="d.id"
                      type="radio"
                      v-model="form.design_id"
                      :value="d.id"
                    />
                    <v-card
                      @click="changeIndex(d.id)"
                      class="design"
                      elevation="1"
                    >
                      <img :src="d.preview" class="" style="width: 100%;" />
                      <v-card-text class="py-1 px-3">
                        <p class="s-16 text-black font-weight-medium">
                          {{ d.name }}
                        </p>
                      </v-card-text>
                    </v-card>
                  </label>
                </div>
              </div>
              <div class="mt-2">
                <p class="ms-4 fs-14 text-black font-weight-medium mb-0 py-4">
                  Complete Data Credential
                </p>
              </div>
              <div class="col-md-12 px-0">
                <div class="col-md-12 px-0 py-4">
                  <v-divider></v-divider>
                </div>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14"
                      >University Accreditation Number</v-subheader
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      :rules="[rules.required]"
                      outlined
                      v-model="
                        form.fields.credentialSubject.degree.accreditationNumber
                      "
                      class=""
                      placeholder="Input University Accreditation Number "
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14"
                      >Degree Type</v-subheader
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      :rules="[rules.required]"
                      outlined
                      v-model="form.fields.credentialSubject.degree.type"
                      placeholder="Input Degree Type"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14"
                      >Degree Major</v-subheader
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      :rules="[rules.required]"
                      outlined
                      v-model="form.fields.credentialSubject.degree.major"
                      placeholder="Input Degree Major"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14"
                      >Degree Title</v-subheader
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      :rules="[rules.required]"
                      outlined
                      v-model="form.fields.credentialSubject.degree.title"
                      placeholder="Input Degree Title"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14"
                      >Major Accreditation Number</v-subheader>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      :rules="[rules.required]"
                      outlined
                      v-model="form.fields.credentialSubject.degree.MajorAccreditationNumber"
                      placeholder="Input Major Accreditation Number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14"
                      >Issuance Place</v-subheader
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      :rules="[rules.required]"
                      outlined
                      v-model="form.fields.credentialSubject.issuance.place"
                      placeholder="Input Issuance Place"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14"
                      >QR Code</v-subheader
                    >
                  </v-col>
                  <v-col cols="8">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      max-width="60%"
                    >
                      <template v-slot:activator="{on, attrs}">
                        <v-text-field
                        append-icon="ri-calendar-line"
                          clearable
                          readonly
                          placeholder="YYYY-MM-DD"
                          v-bind="attrs"
                          v-on="on"
                          v-model="form.fields.qrcode"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                       v-model="form.fields.qrcode"
                        @change="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
              <div class="col-md-12 px-0 py-4">
                <v-divider></v-divider>
              </div>
              <p class="ms-4 fs-14 text-black font-weight-medium mb-0 py-4">
                Input Signature
              </p>
              <p class="fs-14 ms-4">
                Add a signature for the credential template. Credentials have at
                least 1 signer.
              </p>
              <div class="col-md-12 px-0">
                <v-row
                  v-for="(signature, i) in form.fields.credentialSubject
                    .issuance.authorizedSignature"
                  :key="i"
                >
                  <v-col cols="4">
                    <v-subheader class="text-black font-weight-thin fs-14"
                      >Signature {{ i + 1 }}</v-subheader
                    >
                  </v-col>
                  <v-col cols="8">
                    <div class="d-flex">
                      <v-text-field
                        :rules="[rules.required]"
                        v-model="signature.name"
                        outlined
                        class="mr-2"
                        placeholder="Name"
                      ></v-text-field>
                      <v-text-field
                        :rules="[rules.required]"
                        outlined
                        v-model="signature.position"
                        placeholder="Position"
                      ></v-text-field>
                      <div>
                        <v-btn
                          class="text-right d-block ml-2"
                          style="height: 100%;"
                          outlined
                          color="red"
                          @click="removeSignature(i)"
                        >
                          x
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="align-center text-center">
                    <v-btn
                      class="text-right px-8"
                      color="primary"
                      outlined
                      elevation="0"
                      @click="addSignature()"
                    >
                      Add Signer
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="col-md-12 px-0 py-4">
              <v-divider></v-divider>
            </div>
            <div class="col-md-12 align-end text-end">
              <v-btn
                class="text-right mr-2 px-8"
                color="primary"
                elevation="0"
                :to="{
                  name: 'list-template',
                }"
                outlined
              >
                Cancel
              </v-btn>
              <v-btn
                class="text-right px-8"
                color="primary"
                type="submit"
                elevation="0"
                :disabled="form.design_id == ''"
                @click.prevent="handleSubmit()"
              >
                Create Template
              </v-btn>
            </div>
          </v-card>
          <div class="py-5 text-center">
            <img src="@/assets/images/faq.png" />
            <p class="text-black fs-14 ">
              Can not find what you're looking for? You can make a design
              request to us
            </p>
            <v-btn
              class="text-right px-8"
              color="primary"
              elevation="0"
              :to="{name: 'request-desain'}"
            >
              Submit Request Design
            </v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title class="text-h5 text-black text-center mx-auto">
          Are you sure you want to create a Template
        </v-card-title>

        <v-card-text class="my-3">
          <v-alert outlined type="warning" class="fs-14">
            Check your options before proceeding to template creation
          </v-alert>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="dialog = false"
            class="text-center px-3"
            outlined
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="dialog = false"
            class="text-center px-3"
          >
            Create Template
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="hasMessage">
      {{ message }}
      <template v-slot:action="{attrs}">
        <v-btn color="red" text v-bind="attrs" @click="hasMessage = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import {onMounted, reactive, toRefs} from "@vue/composition-api";
import useFetch from "../../../hooks/useFetch";
import router from "../../../router";
import api from "../../../store/api";
export default {
  name: "Create Template-Pensign Admin",
  created() {
    document.title = "Create Template-Pensign Admin";
  },
  data: () => ({
    dialog: false,
    menu: false,
    date: null,
    type: "",
    items: [
      {
        text: "E-Certificate",
        disabled: false,
        href: "#",
      },
      {
        text: "Template",
        disabled: false,
        href: "/list-template",
      },
      {
        text: "Create Template",
        disabled: true,
        href: "#",
      },
    ],
    ex: ["1", "2", "3"],
    search: "",
    headers: [
      {
        text: "CERTIFICATE TITLE",
        align: "start",
        sortable: false,
        value: "judul",
      },
      {text: "TANGGAL DITERBITKAN", value: "date"},
      {text: "JUMLAH PENERIMA", value: "jumlah"},
      {text: "", value: "aksi"},
    ],
    dates: [],
    rules: {
      required: (value) => !!value || "Filled is Required",
    },
  }),

  setup() {
    const {fetchData} = useFetch();

    const state = reactive({
      designs: [],
      variables: [],
      message: "",
      hasMessage: false,
      form: {
        design_id: "",
        name: "",
        description: "",
        fields: {
          credentialSubject: {
            degree: {
              type: "",
              major: "",
              title: "",
              accreditationNumber: "",
            },
            issuance: {
              place: "",
              authorizedSignature: [
                {
                  name: "",
                  position: "",
                },
              ],
            },
          },
          qrcode: "",
        },
      },
    });
    const getDesignByType = async () => {
      state.form.design_id = "";
      state.variables = [];
      let data = await fetchData({
        url: api.design_list + `?schema=${state.form.type}`,
        method: "get",
      });
      state.designs = data.response.data.design;
    };
    const changeIndex = async (id) => {
      let detailDesign = await fetchData({
        url: api.design_list + "/" + id,
        method: "get",
      });
      state.variables = detailDesign.response.data.variables;
    };

    const handleSubmit = async () => {
      let data = await fetchData({
        url: api.template_create,
        method: "post",
        data: state.form,
      });
      if (!data.hasError) {
        state.hasMessage = true;
        state.message = "Template telah di buat";
        setTimeout(() => {
          router.push({
            name: "list-template",
          });
        }, 1000);
      } else {
        state.hasMessage = true;
        state.message = data.response.data.message;
      }
    };
    const addSignature = () => {
      state.form.fields.credentialSubject.issuance.authorizedSignature.push({
        name: "",
        position: "",
      });
    };

    const removeSignature = (id) => {
      state.form.fields.credentialSubject.issuance.authorizedSignature.splice(
        id,
        1
      );
    };

    onMounted(() => {
      // getDesignByType();
    });

    return {
      ...toRefs(state),
      getDesignByType,
      changeIndex,
      handleSubmit,
      addSignature,
      removeSignature,
    };
  },
};
</script>

<style lang="scss">
.h-full {
  height: 100%;
}
.select-design[type="radio"] {
  display: none;
}
.select-design[type="radio"]:checked + .design {
  background-color: #0068d6 !important;
  p {
    color: white !important;
  }
}
</style>
