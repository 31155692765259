<template>
  <v-row align-content="center" class="">
    <v-col class="mx-auto" md="12" style="min-height: 100vh;">
      <v-row v-show="regis">
        <v-col class="pa-0" md="6" style="min-height: 100vh;background: rgba(0, 104, 214, 1);">
          <div
            :style="{
              backgroundImage: `url('${require('@/assets/images/bg-login.png')}')`,
            }"
            style="min-height: 100vh;background-size: cover;"
          >
            <v-spacer class="py-16"></v-spacer>
            <v-card class="transparent px-10">
              <img src="@/assets/images/logo-white.png" class="mb-3" />
              <p class="text-white font-weight-bold title-bg-login px-2 py-8">
                Solution for your digital document mobility.
              </p>
              <v-card
                class="mx-auto px-5"
                style="border: 1px solid #fff;border-radius: 8px;background: transparent;"
                outlined
              >
                <v-card-title class="text-h6 font-weight-bold text-left pe-5">
                  <span class="white--text">Langkah Mudah Menggunakan Pensign</span>
                </v-card-title>

                <v-window v-model="steps">
                  <v-window-item :value="1">
                    <v-card-text class="text-center" style="min-height: 260px;">
                      <img src="@/assets/images/slide-1.png" />
                      <p class="text-subtitle-1 white--text text--darken-1 font-weight-medium">
                        Daftar dan buat akun <br />Pensign Anda
                      </p>
                    </v-card-text>
                  </v-window-item>

                  <v-window-item :value="2">
                    <v-card-text class="text-center" style="min-height: 260px;">
                      <img src="@/assets/images/slide-2.png" />
                      <p class="text-subtitle-1 white--text text--darken-1 font-weight-medium">
                        Lengkapi data perusahaan Anda
                      </p>
                    </v-card-text>
                  </v-window-item>

                  <v-window-item :value="3">
                    <v-card-text class="text-center" style="min-height: 260px;">
                      <img src="@/assets/images/slide-3.png" />
                      <p class="text-subtitle-1 white--text text--darken-1 font-weight-medium">
                        Buat subdomain Pensign
                      </p>
                    </v-card-text>
                  </v-window-item>

                  <v-window-item :value="4">
                    <v-card-text class="text-center" style="min-height: 260px;">
                      <img src="@/assets/images/slide-4.png" />
                      <p class="text-subtitle-1 white--text text--darken-1 font-weight-medium">
                        Atur pengguna Pensign melalui <br />
                        sistem Pensign
                      </p>
                    </v-card-text>
                  </v-window-item>

                  <v-window-item :value="5">
                    <v-card-text class="text-center" style="min-height: 260px;">
                      <img src="@/assets/images/slide-5.png" />
                      <p class="text-subtitle-1 white--text text--darken-1 font-weight-medium">
                        Lakukan aktivitas penandatanganan di <br />
                        subdomain Pensign
                      </p>
                    </v-card-text>
                  </v-window-item>
                </v-window>

                <!-- <v-divider></v-divider> -->

                <v-card-actions class="px-0">
                  <div class="row">
                    <div id="btn-slide" class="col-md-9">
                      <v-btn
                        :disabled="steps === 1"
                        @click="steps = 1"
                        class="white--text font-weight-bold"
                        text
                        small
                      >
                        1
                      </v-btn>
                      <v-btn
                        :disabled="steps === 2"
                        @click="steps = 2"
                        class="white--text font-weight-bold"
                        text
                        small
                      >
                        2
                      </v-btn>
                      <v-btn
                        :disabled="steps === 3"
                        @click="steps = 3"
                        class="white--text font-weight-bold"
                        text
                        small
                      >
                        3
                      </v-btn>
                      <v-btn
                        :disabled="steps === 4"
                        @click="steps = 4"
                        class="white--text font-weight-bold"
                        text
                        small
                      >
                        4
                      </v-btn>
                      <v-btn
                        :disabled="steps === 5"
                        @click="steps = 5"
                        class="white--text font-weight-bold"
                        text
                        small
                      >
                        5
                      </v-btn>
                    </div>
                    <div class="col-md-3 text-center mx-auto">
                      <v-btn @click="getWa()" text class="white--text ">
                        <v-icon class="mr-1">ri-phone-line</v-icon> Bantuan
                      </v-btn>
                    </div>
                  </div>
                  <!-- <v-spacer></v-spacer> -->
                </v-card-actions>
              </v-card>
              <div class="text-center d-lg-none d-md-none d-sm-block mt-5">
                <v-btn rounded color="white" outlined large to="/registration#daftar">
                  Daftar
                </v-btn>
                <p class="py-3"><v-icon color="white">ri-arrow-down-circle-fill</v-icon></p>
              </div>
            </v-card>
          </div>
        </v-col>
        <v-col id="daftar" class="white" md="6">
          <v-spacer class="py-5"></v-spacer>
          <v-form ref="form" class="px-8 col-md-8 mx-auto" v-model="valid">
            <!-- @submit.prevent="submit" -->
            <div class="mx-auto text-left mb-3 text-black">
              <p class="font-weight-bold title-login">Daftar</p>
              <p class="text-body-1">
                Daftar sekarang untuk mendapatkan solusi transaksi digital yang mudah
              </p>
            </div>
            <v-alert class="mt-2" type="error" text dense prominent v-show="response !== null">{{
              response
            }}</v-alert>
            <v-row>
              <v-col class="py-0">
                <div class="subtitle-1 mb-2 text-black mt-3">Nama Lengkap</div>
                <v-text-field
                  filled
                  v-model="fullname"
                  :rules="[...rules('Nama lengkap', 'required'), validate]"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <div class="subtitle-1 mb-2 text-black mt-3">Email</div>
                <v-text-field
                  filled
                  v-model="email"
                  :rules="[...rules('Alamat email', 'required'), validate]"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0">
                <div class="mb-2 subtitle-1 text-black mt-3">Kata Sandi</div>
                <v-text-field
                  filled
                  v-model="password"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show"
                  outlined
                  hide-details="auto"
                  :rules="rules('Kata sandi', 'required')"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mb-6">
              <v-col lg="6" md="12" sm="12">
                <div class="subtitle-1 mb-2 text-black mt-3">Nama Perusahaan</div>
                <v-text-field
                  filled
                  v-model="company_name"
                  :rules="[...rules('Nama perusahaan', 'required'), validate]"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col lg="6" md="12" sm="12">
                <div class="subtitle-1 mb-2 text-black mt-3">Nomor Telepon</div>
                <v-text-field
                  type="number"
                  filled
                  v-model="phone"
                  :rules="[...rules('Nomor telepon', 'required'), validate]"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col class="py-0" cols="12">
                <div class="subtitle-1 mb-2 text-black mt-3">Provinsi</div>
                <v-select
                  v-model="province_id"
                  filled
                  required
                  outlined
                  hide-details="auto"
                  :items="provinces"
                  item-text="name"
                  item-value="id"
                  @change="getCities($event)"
                >
                </v-select>
              </v-col>
              <v-col class="py-0" cols="12">
                <div class="subtitle-1 mb-2 text-black mt-3">Kabupaten/Kota</div>
                <v-select
                  v-model="city_id"
                  filled
                  required
                  outlined
                  hide-details="auto"
                  :items="cities"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12" class="py-0 text-center">
                <p class="text-muted">By creating an account, you accept our Terms and Conditions.</p>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" class="py-0">
                <v-btn
                  large
                  elevation="0"
                  color="primary"
                  :loading="submiting"
                  block
                  @click="submit"
                >
                  <!-- type="submit" -->
                  Mulai Sekarang
                </v-btn>
              </v-col>
            </v-row>
            <v-spacer class="py-8"></v-spacer>
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="text-black">
                  Sudah mempunyai akun ?
                  <router-link
                    class="font-weight-medium text-decoration-none"
                    tabindex="-1"
                    color="primary"
                    :to="{ name: 'login' }"
                  >
                    Login
                  </router-link>
                </span>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-show="step" class="mx-auto" md="6">
          <v-spacer class="py-10"></v-spacer>
          <div class="px-8 white rounded py-3 text-center">
            <div class="mx-auto text-center">
              <img src="@/assets/images/success.png" class="py-3" />
              <p class="font-weight-bold title-login text-black">Selangkah lagi!</p>
            </div>
            <div class="mx-auto text-center mb-3 py-3 text-black">
              <p>
                Selamat! Anda telah berhasil membuat Akun Pensign, silakan verifikasi email Anda
                dengan klik link sudah dikirim ke email Anda.
              </p>
              <p>
                Jika Anda tidak menerima email aktivasi di kotak masuk, silakan periksa folder spam
                Anda. Masih tidak dapat menemukan email? Hubungi tim kami
              </p>
            </div>
            <!-- <v-col cols="12" md="6" class="py-0 mx-auto">
                <v-chip                  
                  elevation="0"
                  color="primary"
                  :loading="submiting"
                  block
                  label
                >
                  Cek Email Saya 
                </v-chip>
              </v-col>                      -->
            <!-- <router-link
              class="font-weight-medium text-decoration-none text-center"
              tabindex="-1"
              color="primary"
              @click="submit()"
            >
              Resend the link
            </router-link> -->
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import validationMixin from '../_mixins/validation';

export default {
  mixins: [validationMixin],
  name: 'login',
  data() {
    return {
      valid: true,
      errors: null,

      regis: true,
      step: false,

      email: '',
      password: '',
      fullname: '',
      phone: '',
      company_name: '',
      province_id: null,
      city_id: null,

      checkbox: false,
      submiting: false,
      show: false,
      response: null,
      steps: 1,
      provinces: [],
      cities: [],
    };
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
  },
  mounted() {
    this.getProvinces();
  },
  methods: {
    async getProvinces() {
      const {
        data: { data: provinces },
      } = await this.axios.get('econtract/master/province');
      this.provinces = provinces;
    },
    async getCities(province_id) {
      this.city_id = null
      const {
        data: { data: cities },
      } = await this.axios.get('econtract/master/city/?province_id=' + province_id);
      this.cities = cities;
    },
    getWa() {
      window.open('https://wa.me/6285795641917');
    },
    submit() {
      if (window.gtag) {
        window.gtag('event', 'register', {
          event_category: 'click_button',
          event_label: 'Register Pensign',
        });

        window.gtag('event', 'conversion', {
          send_to: 'AW-10996601130/ER58CLKP1-IDEKqiy_so',
        });
      }
      if (window.fbq) window.fbq('track', 'CompleteRegistration');
      if (window.lintrk) window.lintrk('track', { conversion_id: 10589153 });

      window.lintrk('track', { conversion_id: 10589153 });
      console.log('ah');
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.submiting = true;
          let data = {
            fullname: this.fullname,
            phone: this.phone,
            email: this.email,
            company_name: this.company_name,
            password: this.password,
            province_id: this.province_id,
            city_id: this.city_id
          };

          this.axios
            .post(`auth/dashboard/register`, data, {
              headers: { 'Content-Type': 'application/json' },
            })
            .then((res) => {
              this.submiting = false;
              if (res.data.code === 200) {
                this.regis = false;
                this.step = true;
              } else {
                this.response = res.data.msg;
                // console.log(res)
                setTimeout(() => {
                  this.response = null;
                }, 3000);
              }
            })
            .catch((error) => {
              this.submiting = false;
              this.$refs.form.reset();
              // console.log('er',error.response)
              this.response = error.response.data.message;
              setTimeout(() => {
                this.response = null;
              }, 3000);
            })
            .finally(() => {
              delete window.gtag_report_conversion;
            });
        }
      }, 50);
    },
  },
};
</script>

<style>
#btn-slide .v-btn.v-btn--disabled {
  background: #fff;
}
</style>
